.filterTooltip {
	width: 200px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15), 0px 0px 8px rgba(0, 0, 0, 0.1), 0px -2px 6px rgba(0, 0, 0, 0.05);
	border-radius: 100px;
	button {
		width: 100%;
		border-radius: 100px;
		padding: 8px 2px 8px 12px;
		& + div {
			box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15), 0px 0px 8px rgba(0, 0, 0, 0.1), 0px -2px 6px rgba(0, 0, 0, 0.05);
			width: 100% !important;
			margin-top: 0 !important;
			border-radius: 8px !important;
			z-index: 10 !important; } }
	.css-1jllj6i-control {
		display: none; }

	.filterTooltipDropdown {
		.css-11unzgr {
			max-height: 260px;
			.css-1vr111p-option,
			.css-1qprcsu-option {
				font-size: 13px;
				font-weight: normal;
				line-height: 20px;
				color: #201158;
				background-color: transparent;
				min-width: auto;
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);
				cursor: pointer; } } } }
