.dashboardPage {
    .statsSlider {
        margin-bottom: 9px;
        &.collapsed {
            // width: 800px
            width: calc(100% - 330px);
            margin-bottom: 0; }
        .embla {
            position: relative;
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
            .embla__viewport {
                overflow: hidden;
                width: 100%;
                &.is-draggable {
                    cursor: move;
                    cursor: grab; }
                &.is-dragging {
                    cursor: grabbing; }
                .embla__container {
                    display: flex;
                    user-select: none;
                    -webkit-touch-callout: none;
                    -khtml-user-select: none;
                    -webkit-tap-highlight-color: transparent;
                    .embla__slide {
                        position: relative;
                        min-width: 208px;
                        // padding-left: 10px;
                        &:last-child {
                            .statsBlock {
                                margin-right: 0; } }
                        .embla__slide__inner {
                            position: relative;
                            overflow: hidden;
 } } } }                            // height: 190px;
            .embla__button {
                position: absolute;
                top: 0;
                border: 1px solid #efefef;
                height: 100%;
                background-color: #fff;
                color: #007bff;
                width: 16px;
                font-size: 20px;
                z-index: 1;
                &:disabled {
                    display: none; } }
            .embla__button--prev {
                left: 0; }
            .embla__button--next {
                right: 0; } }
        .name {
            font-size: 16px;
            line-height: 24px; }
        .statsBlock {
            text-align: center;
            background: #FFFFFF;
            border-radius: 4px;
            margin-right: 8px;
            cursor: pointer;
            // &:last-child
            //     margin-right: 0
            &.collapsed {
                background-color: #fff;
                color: #201158;
                // width: 135px
                border-radius: 100px;
                padding: 8px 16px 6px; }
            &.active {
                background: #007BFF;
                color: #fff;
                cursor: default;
                .sliderHeaderWrapper {
                    .infoIcon {
                        background-image: url(/images/info_icon_active.png); } }
                .exploreWrapper {
                    color: #fff;
                    .exploreIcon {
                        background-image: url(/images/explore_icon_active.png); } } }
            .sliderHeaderWrapper {
                position: relative;
                padding-top: 8px;
                .infoIcon {
                    background-image: url(/images/info_icon.png);
                    background-repeat: no-repeat;
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    right: 8px;
                    top: 8px; } }
            .valueWrapper {
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                margin: 10px 0; }
            .exploreWrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                font-size: 12px;
                line-height: 18px;
                color: #007BFF;
                padding-bottom: 10px;
                .exploreIcon {
                    background-image: url(/images/explore_icon.png);
                    background-repeat: no-repeat;
                    width: 16px;
                    height: 16px; } } } } }
