.right-panel .content .shop-comparison {
    top: 90px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    .selectWrapper {
        display: flex;
        justify-content: end;
        align-items: center;
        .compare-with-title {
            margin-right: 8px;
            min-width: fit-content; }
        .select-wrapper {
            width: 100%;
            min-width: 180px;
            &.dashboardCalendar {
                .react-select__control .react-select__value-container {
                    padding-left: 28px;
                    background: url(/images/calendar_icon.png) 9px 9px no-repeat transparent; }
                .DropdownIndicator {
                    color: #007BFF;
                    margin: -1px 2px 2px 0;
                    i {
                        font-size: 12px; } } }
            .react-select {
                &__control {
                    border-radius: 20px; } }
            // .comparingZones
            .react-select__control {
                background: #fff;
                .react-select__value-container {
                    padding: 4px 6px;
                    &.react-select__value-container--has-value {
                        background-position-y: 11px; }
                    .react-select__multi-value {
                        background: #E9ECEF;
                        border-radius: 100px;
                        border: 1px solid #CED4DA;
                        padding: 3px 0 3px 6px;
                        margin: 0;
                        margin-right: 6px;
                        .react-select__multi-value__label {
                            font-size: 13px;
                            line-height: 20px;
                            padding: 0; } } } } } }
    .card {
        .card-header {
            margin-bottom: 0 !important; }
        .card-body {
            font-size: 13px;
            line-height: 20px;
            .graphWrapper {
                margin-top: 25px;
                .title {
                    margin-bottom: 10px; } } } }

    .visitors {
        .visitors-block {
            width: auto; } }

    .calendar-icon {
        opacity: 0.4;
        margin-left: 20px;
        pointer-events: none;
        &.enabled {
            opacity: 1;
            cursor: pointer;
            pointer-events: all; } }

    .timeline-wrapper {
        max-height: calc(100vh - 160px);
        width: 89%;
        overflow-x: hidden;
        overflow-y: auto;
        margin: 0;
        .selected-range {
            font-size: 13px;
            line-height: 20px;
            color: #747474;
            .time-range {
                font-size: 23px;
                color: #2d9ae3; } }
        .stats-slider {
            margin-bottom: 20px; } }
    .panel-header {
        .floor-title {
            margin-right: 40px; } }
    .shop-row {
        border: 1px solid #707070;
        padding: 3px 0;
        margin-bottom: 15px;
        color: #747474;
        font-size: 28px;
        .shop-names {
            margin: auto 0;
            padding: 0 1rem; }
        .title {
            font-size: 21px;
            line-height: 24px;
            font-weight: 600; }
        .visitors {
            font-size: 29px;
            line-height: 31px;
            font-weight: 200; } }
    .block-header {
        img {
            margin-right: 25px;
            margin-top: -5px; }
        .header {
            color: #747474;
            font-size: 17px;
            letter-spacing: 0.3px;
            font-weight: 600; } }
    .shop-comparison-row {
        .shop-image {
            img {
                width: 320px;
                height: auto;
                max-height: 210px; } }
        .doughnuts {
            .doughnut-wrapper {
                padding: 0;
                .filter-toggle {
                    cursor: auto; } } } }
    .steps {
        display: flex;
        width: 349px;
        justify-content: space-between;
        font-size: 36px;
        line-height: 36px;
        .value {
            position: relative;
            &.in {
                color: #56ca61; }
            &.out {
                color: #004978; }
            .steps-in {
                background: url(/images/steps_in.png) no-repeat center center;
                position: absolute;
                top: -102px;
                left: 48px;
                height: 240px;
                width: 75px;
                background-size: contain; }
            .steps-out {
                background: url(/images/steps_out.png) no-repeat center center;
                position: absolute;
                top: -102px;
                left: -64px;
                height: 240px;
                width: 75px;
                background-size: contain; } } }
    .input-range__label--bottom-max, .input-range__label--bottom-min {
        display: none; } }


#style-3::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius:  10px 50px 50px 10px;
    background-color: transparent; }
#style-3::-webkit-scrollbar {
    width: 15px;
    background-color: transparent; }
#style-3::-webkit-scrollbar-thumb {
    height: 10%;
    border-radius: 10px;
    box-shadow: 0 2px 2px #000000;
    background-color: rgba(26, 12, 99, 0.82); }
