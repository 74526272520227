.dimScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1000;
  div {
    .adm {
      position: absolute;
      height: 300px;
      width: 300px;
      animation-duration: 10s;
      animation-name: adm;
      animation-iteration-count: infinite;
      input {
        left: 5px;
        width: 140px;
        display: block;
        z-index: 5;
        position: absolute;
        top: calc(50% - 15px); } } } }
.dimScreen.disabled {
  display: none; }


@keyframes adm {
  from {
    top: 0;
    left: 0; }
  20% {
    top: 0;
    left: calc(100% - 300px);
    transform: rotate(0deg); }
  25% {
    transform: rotate(90deg); }
  30% {
    top: calc(50% - 150px);
    left: calc(100% - 300px);
    transform: rotate(180deg); }
  50% {
    top: calc(100% / 3);
    left: 0;
    transform: rotate(180deg); }
  55% {
    transform: rotate(90deg); }
  60% {
    top: calc(100% - 300px);
    left: 0;
    transform: rotate(0deg); }
  80% {
    top: calc(100% - 300px);
    left: calc(100% - 300px);
    transform: rotate(0deg); }
  85% {
    top: calc(100% - 300px);
    left: calc(100% - 300px);
    transform: rotate(-135deg); }
  95% {
    transform: rotate(-135deg); }
  to {
    top: 0;
    left: 0;
    transform: rotate(0deg); } }
