.main-wrapper.rtl {
  direction: rtl;

  aside.left-panel {
    &.hide {
      box-shadow: 5px 0 4px 0 rgba(0, 0, 0, 0.5); }
    .item-wrapper {
      .slider {
        right: 0; } } }

  .right-panel {
    margin-left: 0;
    margin-right: 8%;
    &.hide {
      margin-right: 4%;
      .content {
        .timeline-wrapper.sticky {
          right: 4%;
          left: 0; }
        .comparison {
          &.events-comparison, &.shop-comparison {
            left: 0 !important;
            right: 4% !important; } } } }

    header {
      left: 0;

      .header-left {
        .navbar-brand {
          margin-right: 0;
          margin-left: 1rem; } }

      .header-right {
        float: left !important;

        .input-group {
          .input-group-prepend {
            .search-btn {
              padding: 0 20px 0 1px;
              border-radius: 0 21px 21px 0; } }

          input.search-input {
            border-radius: 21px 0 0 21px; } }

        .navbar {
          & > .nav-item {
            margin-right: 0;
            margin-left: 25px; } }

        .setting-dropdown {
          .user-avatar {
            margin-right: 0;
            margin-left: 10px; }

          .dropdown-menu {
            .dropdown-nav_item {
              text-align: right;
              &.language-dropdown.dropright {
                .dropdown-toggle {
                  position: relative;
                  text-align: right;
                  &:after {
                    position: absolute;
                    right: -13px;
                    top: 50%;
                    transform: translateY(-50%); } }
                .dropdown-item {
                  text-align: right; } } } } } } }

    .content, .block_wrapper {
      .wrap_header {
        .btn_new_item, .dropdown_btn {
          margin-right: 50px !important;
          margin-left: initial !important; } }
      .dataTable, .data_table {
        thead {
          th {
            text-align: right;
            &.sorting, &.sorting_asc, &.sorting_desc {
              &:before {
                right: 0;
                left: initial; } } } }
        tbody {
          tr {
            td {
              text-align: right; } } }
        .dataTables_length {
          display: flex; }
        .dataTables_filter {
          display: flex;
          justify-content: flex-end; }
        .dataTables_paginate {
          .pagination {
            .page-item {
              &:first-child {
                transform: rotateY(0deg);
                margin-left: 20px;
                margin-right: initial; }
              &:last-child {
                transform: rotateY(180deg);
                margin-left: initial;
                margin-right: 20px; } } } } }
      .timeline-wrapper {
        .header-block {
          .selected-range {
            .calendar-icon {
              margin-left: 0;
              margin-right: 20px; } } }
        .stats-slider {
          .time-label {
            &.left {
              margin-left: 20px;
              margin-right: 0; }
            &.right {
              margin-right: 25px;
              margin-left: 0; } }
          .input-range__slider-container {
            .input-range__label-container {
              left: 31.5%; } } }

        &.sticky {
          right: 8%;
          left: 0;
          transition: right 0.3s ease 0.05s; }
        &.visible_shops_list.sticky {
          left: 18%; } }

      .topshops {
        .tab-content {
          .list-group-item {
            .shop-name {
              text-align: right; } } } }

      .mall-map {
        .img-magnifier-container {
          direction: ltr; }
        .dropdown_wrap {
          .heatmap_wrap {
            margin-right: 0 !important;
            margin-left: 1.5rem !important;

            span {
              margin-right: 0 !important;
              margin-left: 0.5rem; }
            .react-toggle {
              transform: rotateY(180deg);
              .react-toggle-track-check {
                svg {
                  transform: rotateY(180deg); } } } }
          .dropdown-menu {
            .dropdown-item {
              text-align: right; } } } }

      .calendar-heatmap {
        .week-day {
          text-align: right; } }
      .store_list-wrap, .events_list-wrap {
        left: 0;
        .header {
          .wrap_head, .wrap_for_amount_of_list {
            .selected {
              margin-left: initial;
              margin-right: 0.5rem; } } }
        .data {
          .search_input {
            padding: 5px 50px 5px 0;
            background: 4%/185% 58% url("/images/search.svg") #ffffff no-repeat; }
          .events_wrap {
            &.scrollbar {
              margin-right: initial;
              margin-left: 3px; }
            .event_card {
              .close_btn {
                left: 6px; } } } } }
      .comparison {
        &.events-comparison {
          left: 0 !important;
          right: 8% !important; }

        &.shop-comparison {
          left: 0 !important;
          right: 8% !important;
          .shop-image {
            img {
              float: right; } }
          .steps {
            .steps-in {
              left: -70px;
              transform: rotateY(180deg); }
            .steps-out {
              left: 55px;
              transform: rotateY(180deg); } }
          .full-screen-launcher.cls {
            top: 5px; }
          .block-header {
            direction: ltr; }
          .selected-range1 {
            img {
              margin-left: initial;
              margin-right: 20px; } }
          .panel-header {
            .floor-title {
              margin-right: initial;
              margin-left: 40px; } }
          .compare-with-title {
            margin-right: initial;
            margin-left: 20px; } } }
      .centralise {
        left: 0;
        width: 92%;
        .create_user {
          .description_user-type {
            margin: 20px 0 20px 20px;
            border-radius: 20px 0 0 20px;
            .info_wrap {
              padding-right: 20px;
              padding-left: initial;
              text-align: right; } } } }
      .visitors {
        .bars-wrapper {
          .compare-with {
            margin-left: 20px;
            margin-right: auto; }
          .full-screen-launcher {
            left: 5px;
            right: initial;
            transform: rotateY(180deg); } } }
      .bar-wrapper {
        .return {
          animation-name: swimming-rtl;
          right: 5px;
          left: initial; } }
      &.camera-heatmap {
        .edit_title {
          text-align: right; }
        .stepper {
          .stepper-number {
            .stepper-index {
              margin-right: initial;
              margin-left: 5px; } } }
        .camera-mapping-anchor {
          .map-title {
            text-align: right; } }
        .camera-mapping-demarcation {
          .map-title {
            text-align: right; }
          .add-polygon {
            margin-right: 0; }
          .apply-map {
            margin-right: 32px; } } }
      .profile {
        .main {
          .edit {
            transform: rotateY(180deg);
            right: initial;
            left: 15px; }
          table {
            tr {
              td {
                text-align: right; } } } } } } }

  .dropdown-toggle:not(#drop_for_mall_floors) {
    &:after {
      margin-left: 0 !important;
      margin-right: 0.255em !important; } }
  .close_btn {
    left: 13px;
    right: initial !important; }

  .add_new_data, .sign_up_block {
    h3 {
      text-align: right; }
    .form-label {
      width: 100%;
      text-align: right; }
    .draw-polygon-description {
      text-align: right; }
    .buttons {
      justify-content: flex-start;
      .add-polygon.zones {
        margin-right: 0;
        margin-left: 32px; } }
    .col.right {
      text-align: left !important; }
    .col.left {
      text-align: right !important; }
    .col {
      .button {
        &.mr20 {
          margin-left: 20px; } } } }
  .sign_up_block {
    .description {
      text-align: right; } } }


.start_page_wrapper.rtl {
  direction: rtl;
  .main_block {
    .log_in_block {
      .language-selector {
        left: 10px;
        right: initial;
        .dropdown-menu {
          .dropdown-item {
            text-align: right; } } }
      .close_btn {
        right: -3px;
        left: initial; }
      h3, .description, .copyright {
        text-align: right; }
      .form-label {
        width: 100%;
        text-align: right; } } } }

.redux-toastr {
  &.rtl {
    div {
      .top-right {
        left: 0;
        right: initial;
        div {
          div.toastr {
            div {
              .rrt-left-container {
                right: 0;
                left: initial; }
              .rrt-middle-container {
                margin-left: 33px;
                text-align: right; }
              .rrt-right-container {
                .close-toastr.toastr-control {
                  left: 0;
                  right: initial; } } } } } } } } }
