.dashboardPage {
    .headerWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        &.collapsed {
            margin-bottom: 5px; }
        .page_title {
            font-size: 36px;
            line-height: 44px;
            color: #21ADB8;
            margin-bottom: 0; }
        .swapStore {
            background: #21ADB8;
            color: #fff;
            font-size: 12px;
            line-height: 18px;
            font-weight: 500;
            padding: 2px 8px;
            border: none;
            border-radius: 20px;
            outline: none; }
        .timeline-wrapper {
            width: calc(100% - 230px);
            margin-right: 40px;
            margin-top: 8px;
            .input-range__label-container {
                left: -9999px !important; }
            // .input-ran/Zge__label
            //  // display: none
            .timelineLabelsWrapper {
                display: flex;
                justify-content: space-between;
                padding: 0;
                margin: -3px -15px 0px -11px;
                .timelineLabel {
                    display: inline;
                    font-size: 10px;
                    color: #6C757D;
                    &:first-child,&:last-child {
                        color: #201158; } } } } }
    .selected-range {
        .input-group {
            cursor: pointer;
            .form-control {
                border-left: none;
                border-right: none;
                padding-right: 24px;
                border-radius: 0px 20px 20px 0px;
                cursor: pointer;
                background-color: rgb(255, 255, 255);
                font-size: 13px;
                line-height: 20px;
                height: 40px;
                padding-left: 0; }
            .arrowDown {
                display: flex;
                position: absolute;
                right: 0px;
                top: 0px;
                .separator {
                    background-color: rgb(204, 204, 204);
                    margin-bottom: 7px;
                    margin-top: 8px;
                    width: 1px; }
                .arrow {
                    color: rgb(204, 204, 204);
                    display: flex;
                    padding: 9px 8px 9px 5px;
                    border-top: 1px solid rgb(206, 212, 218);
                    border-right: 1px solid rgb(206, 212, 218);
                    border-radius: 0px 20px 20px 0px;
                    svg {
                        display: inline-block;
                        fill: currentcolor;
                        line-height: 1;
                        stroke: currentcolor;
                        stroke-width: 0; } } } } }
    .separatorCollapse {
        margin-bottom: 30px;
        border-top: 2px solid #007BFF;
        text-align: center;
        padding: 0;
        line-height: 18px;
        .collapseBtn {
            color: #fff;
            background-color: #007BFF;
            border-radius: 0 0 8px 8px;
            font-size: 12px;
            line-height: 18px;
            padding: 0 25px;
            .title {
                margin-right: 9px; }
            &:hover {
                color: #fff;
                background-color: #0069d9 !important;
                border-color: #0062cc; } }
        &.collapsed {
            border-top: none;
            border-bottom: 2px solid #007BFF;
            text-align: right;
            .collapseBtn {
                border-radius: 8px 8px 0 0; } } }
    .img-magnifier-container {
        width: 100% !important;
        height: auto !important; }
    .comparison.swapStorePopup {
        .card {
            min-width: 200px;
            padding: 16px;
            .card-header {
                margin: 0 0 24px 0 !important;
                padding: 0;
                .title {
                    color: #201158;
                    font-size: 20px;
                    line-height: 24px;
                    font-weight: 500;
                    position: relative;
                    .close_btn {
                        top: 2px;
                        right: 0;
                        &:after,
                        &:before {
                            background-color: #495057;
                            height: 18px; } } } }
            .card-body {
                padding: 0 !important;
                margin-bottom: 8px;
                .mallItem {
                    display: block;
                    font-size: 13px;
                    line-height: 20px;
                    padding: 8px;
                    color: #201158;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    &.active {
                        background: #007BFF;
                        color: #fff; }
                    &:last-child {
                        border-bottom: none; } } } } } }
