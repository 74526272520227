aside {
    &.left-panel {
        position: fixed;
        top: 0;
        overflow: auto;
        display: table-cell;
        height: 100vh;
        min-height: 100%;
        width: 8%;
        background: #201158;
        vertical-align: top;
        z-index: 15;
        transition: all 0.3s ease 0.05s;
        .navbar-header {
            margin-top: 30px;
            margin-bottom: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            .hamburger {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 24px;
                width: 28px;
                cursor: pointer;
                span {
                    position: relative;
                    width: 100%;
                    height: 2px;
                    background-color: #ffffff;
                    transition: all 0.3s ease 0.05s;
                    &:after, &:before {
                        transition: all 0.3s ease 0.05s;
                        left: 0;
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 2px;
                        background-color: #ffffff; }
                    &:before {
                        top: 10px; }
                    &:after {
                        top: -10px; } } } }

        ul#sidebarnav {
            padding: 0;
            li.sidebar-item {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.3s ease 0.05s;
                &:after {
                    content: "";
                    position: absolute;
                    width: 62%;
                    height: 0.6px;
                    background-color: rgba(255, 255, 255, .5);
                    bottom: 0;
                    transition: all 0.3s ease 0.05s; }
                .item-wrapper {
                    padding: 28px 0;
                    transition: all 0.3s ease 0.05s;
                    a,button {
                        color: #ffffff;
                        font-size: 12px;
                        background: none;
                        border: none;
                        display: inline-block;
                        opacity: 0.3;
                        transition: all 0.3s ease 0.05s;
                        .menu-title {
                            transition: all 0.3s ease 0.05s; }
                        img {
                            transition: all 0.3s ease 0.05s;
                            margin-bottom: 6px; }
                        &.active {
                            opacity: 1;
                            .slider {
                                display: block; } }
                        &:not(.link-disabled):hover {
                            text-decoration: none;
                            opacity: 1;
                            transition: all 0.3s ease 0.05s; }
                        .slider {
                            position: absolute;
                            width: 5px;
                            background-color: #fff;
                            height: 100%;
                            border-radius: 2.5px;
                            top: 0;
                            left: 0;
                            display: none; } } }
                &:last-child {
                    .item-wrapper {
                        border: none; } }
                &.selected {
                    .slider {
                        display: block; } } } } }

    &.left-panel.hide {
        box-shadow: -5px 0 4px 0 rgba(0, 0, 0, 0.5);
        transition: all 0.3s ease 0.05s;
        width: 4%;
        .navbar-header {
            .hamburger {
                span {
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    transition: all 0.3s ease 0.05s;
                    &:after, &:before {
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        transition: all 0.3s ease 0.05s; } } } }
        ul#sidebarnav {
            li.sidebar-item {
                &:after {
                    display: none; }
                .item-wrapper {
                    padding: 16px 0 !important;
                    transition: all 0.3s ease 0.05s;
                    img {
                        transform: scale(0.8);
                        transition: all 0.3s ease 0.05s;
                        margin-bottom: 0 !important; } }
                .menu-title {
                    font-size: 0;
                    transition: all 0.3s ease 0.05s; } } } } }

#style-5::-webkit-scrollbar {
    display: none; }
#style-5 {
    -ms-overflow-style: none; }
#style-5 {
    overflow: -moz-scrollbars-none;
    scrollbar-width: none; }
