.btn {
    &.btnDelete {
        padding-left: 36px;
        background-image: url(/images/zone/delete.png) !important;
        background-repeat: no-repeat !important;
        background-position: 16px 9px!important;
        color: #E14E5C;
        border-color: #E14E5C;
        &.btn-disabled {
            background-image: url(/images/zone/deleteDisabled.png) !important; }
        &:hover {
            background-repeat: no-repeat !important;
            background-position: 16px 9px!important;
            background-image: url(/images/zone/deleteHover.png) !important; } } }

.add_new_data.zonesForm {
    padding: 24px 32px;
    // height: 843px
    .navTabs {
        border: none;
        .nav-item {
            font-size: 24px;
            line-height: 24px;
            color: #201158;
            border: none;
            &.active {
                color: #201158;
                border-bottom: 2px solid #007BFF; } } }
    .bodyWrapper {
        height: 630px;
        display: flex;
        flex-direction: column;
        position: relative;
        .search-btn {
            background: #fff;
            color: #C4C4C4;
            border-color: #CED4DA;
            border-right: none;
            padding: 5px 10px; }
        .search-input {
            padding-left: 0;
            font-size: 13px;
            line-height: 20px;
            &:focus {
                border-color: #CED4DA;
                border-left: none; } }
        .departmentsList {
            // height: 240px
            overflow: auto;
            background: #E9ECEF;
            border-radius: 8px;
            padding: 8px;
            min-height: 100%;
            .departmentItem {
                background-color: #fff;
                font-size: 13px;
                line-height: 20px;
                padding: 10px 8px;
                border-radius: 4px;
                margin-bottom: 8px;
                cursor: pointer;
                position: relative;
                &.active {
                    background-color: #007BFF;
                    color: #fff;
                    .iconWrapper {
                        .icon {
                            color: #fff; } } }
                &.selected {
                    .iconWrapper {
                        .icon {
                            font-size: 18px;
                            padding: 12px 6px 11px 6px; } } }
                &.deepDepartmentItem {
                    cursor: auto; }
                .iconWrapper {
                    display: flex;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    cursor: pointer;
                    .separator {
                        background-color: rgba(0, 0, 0, 0.1);
                        margin-bottom: 8px;
                        margin-top: 8px;
                        width: 1px; }
                    .icon {
                        color: #007BFF;
                        font-size: 12px;
                        padding: 15px 9px 14px 9px;
                        display: flex; } } } }
        .selectedDepartments {
            background: #F0F0F0;
            border-radius: 8px;
            padding: 16px;
            font-size: 13px;
            line-height: 20px;
            .rowDepartments {
                margin-bottom: 8px;
                &:last-child {
                   margin-bottom: 0; }
                .title {
                    color: #495057;
                    margin-right: 10px; }
                .departmentNode {
                    background: #E9ECEF;
                    border: 1px solid #CED4DA;
                    box-sizing: border-box;
                    border-radius: 4px;
                    color: #201158;
                    padding: 3px 22px 3px 8px;
                    margin: 0 0 8px 8px;
                    position: relative;
                    display: inline-block;
                    .close_btn {
                        right: 7px;
                        top: 7px;
                        width: 10px;
                        height: 10px;
                        padding: 0;
                        border: none;
                        &:before,&:after {
                            height: 10px; }
                        &:focus {
                            background: none; } } } } } }
    .btn {
        font-family: Poppins, inherit; //Poppins hasn't hebrew support
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        box-sizing: border-box;
        border-radius: 4px;
        border-width: 1px;
        border-style: solid;
        &.add-polygon {
            padding: 10px 17px 10px 36px;
            margin-right: 32px;
            color: #007BFF;
            background-image: url(/images/zone/pen.png) !important;
            background-repeat: no-repeat !important;
            background-position: 16px center !important;
            &:hover, &:focus {
                background-image: url(/images/zone/penHover.png) !important;
                color: #fff;
                background-color: #007bff !important;
                border: 1px solid #007bff !important; }
            &.btn-disabled {
                background-image: url(/images/zone/penDisabled.png) !important; } }
        &.clear-polygon {
            padding: 10px 17px 10px 36px;
            padding-left: 36px;
            color: #E14E5C;
            background-image: url(/images/zone/delete.png) !important;
            background-repeat: no-repeat !important;
            background-position: 16px center !important;
            border: 1px solid #E14E5C !important;
            &:hover, &:focus {
                background-image: url(/images/zone/deleteHover.png) !important;
                background-color: #dc3545 !important;
                color: #fff; }
            &.btn-disabled {
                background-image: url(/images/zone/deleteDisabled.png) !important; } }
        &.btn-disabled {
            background-color: #E6E6E6 !important;
            border-color: #b3b3b3 !important;
            cursor: auto !important;
            &:hover, &:focus {
                background-color: #E6E6E6 !important; } } }

    .zoneOption {
        .form-group {
            .dropdown-toggle {
                width: 100%; } } }
    .map-title {
        font-size: 13px;
        margin-bottom: 8px; }
    .map-block {
        position: relative;
        overflow: hidden;
        height: 600px;
        border: 2px solid #B3B3B3;
        background: #E6E6E6;
        .no-floor-image {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center; }
        .drawing {
            cursor: url(/images/mapping/cursor.png), auto;
            svg {
                circle:hover {
                    cursor: url(/images/mapping/cursor.png), auto; } } }
        svg {
            position: absolute;
            circle:hover {
                cursor: pointer;
                background: yellow; } } }

    .footer {
        .btn {
            padding: 10px 20px; } } }
