.img-magnifier-glass {
  display: none;
  background: #fff;
  position: fixed;
  border: 1.5px solid #201158;
  border-radius: 50%;
  cursor: none;
  width: 100px;
  height: 100px;
  box-shadow: 2px 2px 5px -2px #000; }

.legend-area {
  position: absolute;
  bottom: 3px;
  left: 3px;
  padding: 10px;
  background: white;
  outline: 3px solid black;
  line-height: 1em;
  z-index: 10;
  .minmax {
    width: 100%;
    display: flex;
    justify-content: space-between; } }

#tooltip {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  background: #201158;
  color: white;
  font-size: 14px;
  padding: 5px;
  line-height: 18px;
  z-index: 15; }

.floor-spinner_wrap {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center; }
