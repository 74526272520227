@font-face {
    font-family: 'Poppins';
    src: local('Poppins Light'), local('Poppins-Light'), url('/fonts/poppins/Poppinslight.woff2') format('woff2'), url('/fonts/poppins/Poppinslight.woff') format('woff'), url('/fonts/poppins/Poppinslight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal; }
@font-face {
    font-family: 'Poppins';
    src: local('Poppins Black'), local('Poppins-Black'), url('/fonts/poppins/Poppinsblack.woff2') format('woff2'), url('/fonts/poppins/Poppinsblack.woff') format('woff'), url('/fonts/poppins/Poppinsblack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal; }
@font-face {
    font-family: 'Poppins';
    src: local('Poppins Regular'), local('Poppins-Regular'), url('/fonts/poppins/Poppinsregular.woff2') format('woff2'), url('/fonts/poppins/Poppinsregular.woff') format('woff'), url('/fonts/poppins/Poppinsregular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal; }
@font-face {
    font-family: 'Poppins';
    src: local('Poppins Poppins-BoldSemiBold'), local('Poppins-SemiBold'), url('/fonts/poppins/Poppinspoppins-boldsemibold.woff2') format('woff2'), url('/fonts/poppins/Poppinspoppins-boldsemibold.woff') format('woff'), url('/fonts/poppins/Poppinspoppins-boldsemibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal; }
@font-face {
    font-family: 'Poppins';
    src: local('Poppins Poppins-SemiBoldMedium'), local('Poppins-Medium'), url('/fonts/poppins/Poppinspoppins-semiboldmedium.woff2') format('woff2'), url('/fonts/poppins/Poppinspoppins-semiboldmedium.woff') format('woff'), url('/fonts/poppins/Poppinspoppins-semiboldmedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal; }
@font-face {
    font-family: 'Poppins';
    src: local('Poppins Bold'), local('Poppins-Bold'), url('/fonts/poppins/Poppinsbold.woff2') format('woff2'), url(/fonts/poppins/'Poppinsbold.woff') format('woff'), url('/fonts/poppins/Poppinsbold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal; }

body {
    margin: 0;
    font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #201158; }

.pink {
    color: #E010D0 !important;
    border-color: #E010D0 !important; }
.blue {
    color: #5890E1 !important;
    border-color: #5890E1 !important; }
.green {
    color: #397E6F !important;
    border-color: #397E6F !important; }
.yellow {
    color: #F5A623 !important;
    border-color: #F5A623 !important; }
.purple {
    color: #9013FE !important;
    border-color: #9013FE !important; }
.dark {
    color: #707070 !important;
    border-color: #707070 !important; }
.girl-pink {
    background-color: #E575A3; }
.female-blue {
    background-color: #5DB2E3; }
.male-blue {
    background-color: #004978; }
.returns {
    background-color: #A9A9A9; }
.bg-g {
    background-color: #2E8270 !important; }
.bg-c {
    background-color: #8AC4A4 !important; }
.bg-b {
    background-color: #50E3C2 !important; }
.bg-w {
    background-color: #DCDCDC !important; }

.row {
    margin-right: -10px;
    margin-left: -10px; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
    padding-right: 10px;
    padding-left: 10px; }

.form-control:focus,
button:focus:not(.react-datepicker__navigation),
button:active:not(.react-datepicker__navigation) {
    box-shadow: none !important; }
.btn:active, .btn:hover {
    background-color: #fff !important; }
.btn-empty {
    border: none;
    background: none;
    &:focus {
        outline: none; } }
.btn-outline-primary {
    &:hover {
        color: #007bff; } }
.btn-outline-success {
    border: 1px solid #19AB27;
    color: #19AB27;
    &:hover {
        color: #19AB27; } }
.btn-outline-filling {
    border: 1px solid #ffc107;
    color: #ffc107;
    &:hover {
        color: #ffc107; } }
.btn_new_item {
    padding: 5px 20px;
    border-radius: 0;
    color: #d80aac;
    border-color: #d80aac;
    margin-left: 50px;
    position: relative;
    background-color: transparent;
    &:hover {
        background-color: #d80aac !important;
        border-color: #d80aac !important;
        color: #ffffff; }
    &:active, &:focus {
        border-radius: 0;
        border: 1px solid  #d80aac !important; } }
.btn-disabled,
.btn-disabled:hover {
    background: #E6E6E6 !important;
    border-color: #B3B3B3 !important;
    color: #B3B3B3 !important; }

.link-disabled {
    cursor: auto; }

#calendar-data-range.popover {
    min-width: max-content; }

.input-range {
    height: 24px;
    .input-range__track--background {
        background-color: rgb(199, 199, 199); }
    .input-range__track {
        height: 6px;
        .input-range__track--active {
            // background-color: rgb(32, 17, 88)
            background-color: #007BFF;
            height: 6px;
            transition: left 0.1s ease-out, width 0.1s ease-out; }
        .input-range__slider-container {
            transition: left 0.1s ease-out;
            .input-range__slider {
                border: 1px solid rgb(204, 204, 204);
                height: 20px;
                width: 20px;
                cursor: grab;
                border-radius: 50%;
                background-color: #fff;
                touch-action: pan-x;
                margin-top: -0.8rem;
                &:active {
                    box-shadow: 0 0 5px #201158;
                    transform: scale(1.1); } } }
        .input-range__label-container {
            position: relative;
            background-color: #201158;
            border-radius: 0;
            font-family: sans-serif;
            font-size: 14px;
            letter-spacing: 2px;
            padding: 4px 6px;
            min-width: 24px;
            height: 24px;
            line-height: 1;
            color: #fff;
            text-align: center;
            text-decoration: none;
            box-shadow: 0 0 4px #d9d9d9;
            left: -47.5%;
            top: -12px;
            &:before {
                position: absolute;
                content: "";
                border: 7px solid transparent;
                border-top: 7px solid #201158;
                bottom: -14px;
                margin-left: -7px;
                left: 50%;
                width: 0;
                height: 0; } } }
    .input-range__label--min, .input-range__label--max {
        display: none; } }

.input-range.input-range--disabled {
    .input-range__slider-container {
        .input-range__slider {
            cursor: not-allowed; } } }


.checkmark {
    background: url("/images/checkmark.svg") center no-repeat;
    display: block;
    height: 20px;
    width: 30px;
    margin-left: 5px;
    cursor: pointer; }

.fixed-tooltip {
    position: fixed !important;
    z-index: 8; }

.right-panel.hide {
    margin-left: 4%;
    width: 96%;
    transition: all 0.3s ease 0.05s;
    .content {
        .timeline-wrapper.card {
            &.sticky {
                transition: left 0.3s ease 0.05s;
                left: 4%; } } }
    .centralise.open, .comparison {
        left: 4% !important; } }

.block-wrapper {
    border: 1px solid rgba(32, 17, 88, 0.7);
    margin-bottom: 20px;
    padding: 15px; }

.main-wrapper {
    display: flex; }

.right-panel {
    margin-left: 8%;
    width: 92%;
    min-height: 100vh;
    height: 100%;
    flex-direction: column;
    background-color: #efefef;
    position: relative;
    transition: all 0.3s ease 0.05s;
    .wrap_header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .page_title {
            margin: 10px 0 0 0;
            font-size: 37px;
            color: #21ADB8; }
        .dropdown_btn {
            margin-left: 50px;
            button {
                width: 100%;
                padding: 5px 20px;
                color: #ffffff;
                border-color: #d80aac !important;
                border-radius: 0;
                margin-right: 18px;
                background-color: #d80aac;
                &:hover {
                    background-color: transparent !important;
                    border-color: #af0fbb !important;
                    color: #d80aac; }
                &:active, &:focus {
                    border-radius: 0;
                    border: 1px solid  #af0fac !important; } } }
        .dropdown_btn.reverse {
            button {
                color: #d80aac;
                border-color: #d80aac !important;
                background-color: transparent;
                &:hover {
                    background-color: #d80aac !important;
                    border-color: #af0fbb !important;
                    color: #ffffff; }
                &:active, &:focus {
                    border-radius: 0;
                    border: 1px solid  #ffffff !important; } } }
        .page_title {
            margin: 10px 0 0 0;
            font-size: 37px;
            color: #21ADB8;
            .filter_wrap {
                display: flex;
                justify-content: center;
                .add_new-block {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    .dropdown {
                        width: 45%;
                        margin: 10px;
                        button {
                            width: 100%;
                            background-color: #201158;
                            border: 1px solid #000 !important;
                            &:hover, &:active {
                                background-color: #201158 !important;
                                border: 1px solid #000 !important; } }
                        .dropdown-menu {
                            width: 40%; } } } } } }
    .content {
        padding: 20px 80px;
        margin-top: 90px;
        height: 100%;
        min-height: calc(100vh - 90px);
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
        transition: left, width 0.3s ease 0.05s;
        .timeline-wrapper.card {
            &.sticky {
                position: fixed;
                z-index: 8;
                left: 8%;
                right: 0;
                margin-top: -20px;
                padding: 0 80px;
                border-radius: 0;
                box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.16);
                border-right: none;
                transition: left 0.3s ease 0.05s;
                animation-duration: 0.5s;
                animation-name: showing_sticky;
                animation-iteration-count: 1;
                .card-body {
                    padding-left: 0;
                    padding-right: 0;
                    .double-arrow {
                        height: 20px;
                        width: 16px;
                        position: absolute;
                        right: calc(50% - 8px);
                        cursor: pointer;
                        bottom: 4px;
                        display: block;
                        transform: rotate(-90deg);
                        &.rotated {
                            transform: rotate(90deg); } } } }
            .double-arrow {
                display: none; }
            .card-body {
                padding-top: 1.2rem;
                .header-block {
                    display: flex;
                    justify-content: space-between;
                    .selected-range {
                        font-size: 22px;
                        color: #666060;
                        .calendar-icon {
                            opacity: 0.4;
                            margin-left: 20px;
                            pointer-events: none;
                            &.enabled {
                                opacity: 1;
                                cursor: pointer;
                                pointer-events: all; } } } }
                .stats-slider {
                    display: flex;
                    align-items: flex-end;
                    min-height: 60px;
                    .time-label {
                        &.left {
                            margin-right: 20px;
                            margin-left: 0; }
                        &.right {
                            margin-left: 20px;
                            margin-right: 0; } } } } }
        .timeline-wrapper.card.visible_shops_list {
            &.sticky {
                right: 18%; } }
        .dropdown_wrap {
            display: flex;
            justify-content: flex-end;
            .drop_button {
                padding: 4px 15px;
                border-radius: 15px;
                margin-left: 18px;
                border: 1px solid #000 !important; }
            .period {
                background-color: #201158;
                &:hover, &:active {
                    background-color: #201158 !important; } }
            .malls {
                background-color: #fff;
                color: #000;
                &:hover, &:active {
                    background-color: #FFF!important; } }
            .floor {
                color: #f5a623;
                border: 1px solid #f5a623 !important;
                font-size: 15px;
                font-weight: 300;
                line-height: 23px;
                background-color: #fff; }
            .drop_menu {
                width: 287px;
                padding: 37px;
                margin-top: 12px;
                border-radius: 12px;
                box-shadow: 0 1px 9px rgba(0,0,0,0.11);
                .dropdown-item {
                    &:active {
                        color: initial;
                        background-color: initial; }
                    &.active {
                        color: initial;
                        background-color: initial;
                        font-weight: 600; } }
                &-item {
                    position: relative;
                    border-top: 1px solid #979797;
                    padding: 0 0 20px 0;
                    font-weight: 300;
                    font-size: 15px;
                    &.active {
                        color: initial;
                        background-color: initial;
                        font-weight: 600; } }
                &-item:last-child {
                    border-bottom: 1px solid #979797;
                    padding-bottom: 20px;
                    margin-bottom: 0; } } }
        .card {
            border-radius: 20px;
            box-shadow: 0 1px 9px rgba(0,0,0,0.11);
            margin-bottom: 20px;
            .card-header {
                background-color: transparent;
                padding: 0;
                border-bottom: none;
                margin: 12px 16px 0;
                .panel-header {
                    padding-top: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-weight: 600;
                    border-top: 2px solid #000;
                    font-size: 17px;
                    .title {
                        font-size: 17px;
                        line-height: 26px;
                        letter-spacing: 0.3px; }
                    .btn-menu {
                        .btn {
                            border: none;
                            background-color: #fff; } }
                    .btn-menu {
                        .btn {
                            padding-top: 0;
                            padding-right: 0;
                            width: 16px; } }
                    &.visitors {
                        align-items: initial;
                        padding-top: 0; } }
                .gender {
                    max-width: 250px;
                    display: flex;
                    justify-content: space-between;
                    font-size: 10px;
                    color: #fff;
                    margin-top: 5px;
                    .stat-wrapper {
                        display: flex;
                        align-items: flex-end;
                        img {
                            vertical-align: bottom; }
                        .percentage {
                            padding: 4px 10px;
                            margin-left: 5px; } } } }
            .card-body {
                padding: 0 16px 12px 16px; } }
        .event_button {
            font-size: 15px;
            background: #fff;
            color: #201158;
            padding: 4px 15px;
            border-radius: 15px;
            margin-left: 18px;
            border: 1px solid #201158 !important; }
        .table-head {
            display: flex;
            justify-content: space-between;
            font-size: 1rem;
            text-transform: uppercase;
            color: #50A0A6;
            margin-bottom: 23px;
            list-style: none;
            .table_title {
                margin: 13px 5px 0 10px;
                width: 20%; }
            .table_title.description {
                width: 40%; } }
        .table-item {
            height: 73px;
            background-color: #F6F6F6;
            margin-bottom: 2px;
            &:nth-of-type(odd) {
                background: rgba(80, 80, 100, 0.05); }
            .table-content {
                list-style: none;
                display: flex;
                justify-content: space-between;
                .table-content_item {
                    margin: 13px 5px 0 10px;
                    width: 20%;
                    display: flex;
                    flex-direction: column;
                    font-size: 1rem;
                    color: #201158;
                    text-transform: uppercase;
                    .title {
                        font-weight: 600;
                        margin: 0; }
                    .description {
                        opacity: 0.4; } }
                .table-content_item.description {
                    width: 40%;
                    word-wrap: break-word;
                    text-transform: none; } } }

        .comparison {
            z-index: 12;
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 8%;
            right: 0;
            transition: 0.3s ease 0.05s;
            .card {
                .card-header {
                    margin: 30px; }
                .card-body {
                    padding: 0 30px; } } } }
    .mall_description {
        display: flex;
        justify-content: space-between;
        .main_info {
            width: 79%; }
        .block-schedule {
            width: 19%; }
        .block-schedule {
            border: 1px solid rgba(32, 17, 88, 0.7);
            margin-bottom: 1rem;
            padding: 15px;
            display: flex;
            .form-group {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                width: 100%;
                .form-label {
                    font-size: 1rem;
                    font-weight: 500;
                    text-align: center; } } } }
    .block-floors {
        .row-floor {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            .col-floor-image {
                position: relative;
                max-width: 100px;
                img {
                    width: 100%;
                    max-height: 100px; }
                .form-group {
                    opacity: 0;
                    transition: 0.3s;
                    position: absolute;
                    top: calc(50% - 30px);
                    left: calc(50% - 30px);
                    img {
                        cursor: pointer; } }
                &:hover {
                    .form-group {
                        opacity: 1;
                        transition: .3s; } } } } }
    .admin_wrapper {
        padding: 100px;
        .sign_up_form {
            padding-right: 100px; }
        .add_mall {
            padding-left: 100px; } }
    .block_wrapper {
        margin-top: 90px;
        height: 100%;
        min-height: calc(100vh - 90px); }
    .dataTable, .data_table {
        border-collapse: separate;
        border-spacing: 0 15px;
        margin-bottom: 0;
        thead {
            &:last-child {
                display: none; }
            .th-sm {
                width: 10%; }
            .th-md {
                width: 20%; }
            .th-lg {
                width: 30%; }
            .th-buttons {
                width: 190px; }
            th {
                font-size: 17px;
                font-weight: 500;
                text-transform: uppercase;
                border: none;
                color: #50a0a6;
                background-color: transparent;
                position: relative;
                padding: 15px;
                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 0;
                    height: 0;
                    left: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 10px solid #50a0a6; } }
            th.th-comparison {
                display: flex;
                justify-content: center;
                align-items: center;
                .comparison-checkbox {
                    width: 20px;
                    height: 20px;
                    cursor: pointer; }
                .comparison-btn {
                    width: 20px;
                    cursor: pointer;
                    height: 20px;
                    border: 2px solid #201158;
                    margin: 0 auto;
                    position: relative;
                    &.comparison-minus:before {
                        content: "";
                        position: absolute;
                        width: 80%;
                        height: 3px;
                        background-color: #201158;
                        top: calc(50% - 1.5px);
                        left: 8%; }
                    &.comparison-plus:before {
                        content: "+";
                        font-size: 28px;
                        line-height: 19px;
                        color: #201158; } } }
            th.none_sort {
                &:before {
                    display: none; } }
            th.sorting_asc {
                &:before {
                    transform: rotate(180deg) translateY(50%);
                    border-top: 10px solid red; } }
            th.sorting_desc {
                &:before {
                    border-top: 10px solid red; } } }
        tbody {
            tr {
                cursor: pointer;
                background-color: #ffffff;
                padding: 15px;
                &:hover {
                    background: rgba(199, 205, 251, 0.5);
                    transition: 0.3s; }
                td {
                    color: #201158;
                    vertical-align: inherit;
                    border: none;
                    border-spacing: 0 10px;
                    .form-group {
                        margin-bottom: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .custom-checkbox {
                            padding-left: 2rem;
                            cursor: pointer;
                            .custom-control-label {
                                cursor: pointer; }
                            .custom-control-input {
                                cursor: pointer;
                                &:checked ~ .custom-control-label {
                                    &:before {
                                        cursor: pointer;
                                        border-color: #201158;
                                        background-color: #201158;
                                        top: 0.05rem;
                                        box-shadow: 0 0 0 0.2rem rgba(32,17,88, 0.5); }
                                    &:after {
                                        cursor: pointer;
                                        top: 0.05rem; } } }
                            .custom-control-label {
                                &:before {
                                    cursor: pointer;
                                    top: 0.05rem;
                                    border-radius: 0; } } }
                        .customCheckbox {
                            width: 20px;
                            height: 20px;
                            cursor: pointer; } }
                    .title {
                        font-weight: 600;
                        margin: 0; }
                    .description {
                        opacity: 0.6;
                        width: 40%;
                        word-wrap: break-word;
                        text-transform: none; }
                    .attr {
                        display: block;
                        width: 30px;
                        height: 30px;
                        background-color: #495057;
                        border-radius: 50%;
                        margin: 0 auto; }
                    .attr.active {
                        background-color: #1fc80d; }
                    .ava {
                        display: block;
                        margin: 0 auto;
                        max-width: 40px;
                        max-height: 40px;
                        width: auto;
                        height: auto; }
                    .buttons_wrap {
                        button {
                            width: 45%;
                            &:hover {
                                background: transparent;
                                color: #000; } } } } } }
        .row {
            &:first-child {
                margin-top: 30px;
                .dataTables_filter, .bs-select {
                    label {
                        font-size: 0;
                        margin: 0;
                        input[type="search"], select.custom-select {
                            border-radius: 10px;
                            border: none;
                            outline: none;
                            padding-left: 20px; } } }
                .dataTables_filter {
                    label {
                        float: right;
                        width: 35%; } }
                .bs-select {
                    label {
                        width: 15%; } } }
            &:last-child {
                margin: 0;
                display: flex;
                padding: 5px 10px;
                background-color: #cecccc;
                .col-sm-12.col-md-5 {
                    display: flex;
                    align-items: center; }
                .col-sm-12.col-md-7 {
                    display: flex;
                    .pagination {
                        margin-bottom: 0;
                        .page-item {
                            &:first-child, &:last-child {
                                font-size: 0;
                                background: url("/images/arrow.svg") center/50% no-repeat;
                                a.page-link {
                                    height: 100%; } }
                            &:first-child {
                                transform: rotate(180deg);
                                margin-right: 20px; }
                            &:last-child {
                                margin-left: 20px; }
                            .page-link {
                                background-color: transparent;
                                border: none;
                                color: #d80aac; } }
                        .page-item.active {
                            .page-link {
                                color: #4c4b4b;
                                background-color: #adabad; } }
                        .page-item.disabled {
                            opacity: 0.5;
                            .page-link {
                                color: #898989; } } } } }
            .buttons_wrap {
                display: flex;
                justify-content: space-around;
                .btn {
                    width: 45%; } }
            .page-item {
                cursor: pointer; }
            .page-item.active {
                .page-link {
                    background-color: #201158;
                    border-color: #201158;
                    &:hover {
                        color: #ffffff; } } } } }
    .global_error {
        margin-top: 90px;
        padding: 50px;
        h2 {
            font-size: 40px;
            color: #ea1609;
            margin-bottom: 30px;
            text-align: center; }
        details {
            summary {
                font-size: 24px;
                color: #201158; }
            &:active, :hover, :focus {
                outline: none;
                outline-offset: 0; } } }
    .centralise {
        display: none; }
    .centralise.open {
        display: flex;
        height: calc(100vh - 90px);
        width: 92%;
        position: fixed;
        top: 90px;
        left: 8%;
        right: 0;
        align-items: center;
        justify-content: center;
        z-index: 10;
        transition: 0.3s ease 0.05s; } }

.dropdown.scrollDrop {
    .dropdown-menu.show {
        height: 50vh;
        overflow-y: scroll; } }

.content.event_modal.disabled {
    position: relative;
    &:before {
        //position: absolute
        position: fixed;
        content: "";
        z-index: 5;
        background-color: rgba(0,0,0,0.5);
        height: 100%;
        //width: 100%
        width: 100vw;
        left: 0;
        top: 0; } }

.content.shop_modal.disabled {
    position: relative;
    &:before {
        position: fixed !important;
        content: "";
        z-index: 12 !important;
        background-color: rgba(0,0,0,0.5);
        width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0; } }

.content.visible_events_list {
    width: 79%;
    transition: width 0.3s ease 0.05s; }

.content.visible_shops_list {
    width: 81%;
    transition: width 0.3s ease 0.05s; }

.block_wrapper.admin {
    padding: 20px 80px; }

.block_wrapper.admin.editing {
    padding: 0;
    .add_new_data {
        width: 100%;
        z-index: 0; } }

.block_wrapper.admin.disabled {
    position: relative;
    &:before {
        position: absolute;
        content: "";
        z-index: 5;
        background-color: rgba(0,0,0,0.5);
        height: 100%;
        width: 100%;
        left: 0;
        top: 0; } }

.close_btn {
    position: absolute;
    right: 13px;
    top: 7px;
    border: none;
    background: none;
    height: 20px;
    width: 20px;
    outline: none;
    &:focus {
        outline: none; }
    &:after,&:before {
        content: "";
        position: absolute;
        width: 2px;
        height: 20px;
        background-color: #707070;
        top: 0; }
    &:before {
        transform: rotate(45deg); }
    &:after {
        transform: rotate(135deg); } }

.snapshot_modal-container {
    position: absolute;
    z-index: 20;
    background-color: rgba(0,0,0,0.5);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    .snapshot_modal {
        position: relative;
        padding: 20px 35px;
        max-height: 90%;
        max-width: 90%;
        width: auto;
        display: flex;
        flex-direction: column;
        background: #fff;
        height: auto;
        border: 1px solid rgba(0,0,0,.2);
        border-radius: .3rem;
        .snapshot_modal_title {
            border-bottom: 1px solid #dee2e6;
            margin-bottom: 20px; }
        img {
            max-height: 100%;
            max-width: 100%;
            overflow: scroll; } } }

.full-screen-launcher {
    position: absolute;
    background-color: transparent;
    outline: none !important;
    border: none;
    &:hover {
        transform: scale(1.1); }
    &.cls {
        background-image: url('/images/full_screen.svg');
        background-repeat: no-repeat;
        right: 5px;
        top: 15px;
        background-size: 15px 15px;
        width: 15px;
        height: 15px; }
    &.opn {
        background-image: url('/images/full_screen_toggle.svg');
        background-repeat: no-repeat;
        right: 10px;
        top: 10px;
        background-size: 25px 25px;
        width: 25px;
        height: 25px; } }

.spin_image {
    display: flex;
    justify-content: center;
    img {
        height: auto;
        width: 100%; } }

.return {
    width: 25px;
    height: 25px;
    background-color: transparent;
    border: none;
    outline: none !important;
    background-image: url('/images/return.svg');
    background-repeat: no-repeat;
    background-size: 25px 25px;
    position: absolute;
    top: 5px;
    left: 5px;
    animation-duration: 1.5s;
    animation-name: swimming;
    animation-iteration-count: infinite; }

.bar-wrapper {
    clear: both;
    margin-top: 5px;
    background: #fff;
    position: relative;
    .chartjs-render-monitor {
        direction: ltr; } } // for fixing some style issues with column labels

.language-selector {
    position: absolute;
    right: 10px;
    top: 10px;
    .dropdown-toggle {
        width: 28px;
        height: 28px;
        background: transparent !important;
        border: none !important;
        outline: none !important;
        padding: 0;
        &:after {
            display: none; }
        img {
            width: 100%;
            height: 100%; } }
    .dropdown-menu.show {
        min-width: 110px;
        box-shadow: 0 1px 3px 0 rgba(32, 17, 88, 0.5);
        .dropdown-item {
            &.selected {
                background: rgba(32, 17, 88, 0.05);
                &:hover {
                    background: rgba(32, 17, 88, 0.05); } }
            &:hover {
                text-decoration: none;
                text-transform: none;
                background: rgba(32, 17, 88, 0.05); }
            &:active {
                background: initial;
                color: initial; }
            &:focus {
                outline: none; } } } }


@media (max-width: 1440px) {
    header {
        padding: 0 40px; }
    .right-panel {
        .content {
            padding: 20px 40px; }
        .block_wrapper.admin {
            padding: 20px 40px; } } }

@keyframes showing_sticky {
    from {
        margin-top: -180px; }
    to {
        margin-top: -20px; } }

@keyframes swimming {
    0% {
        transform: scale(1); }
    50% {
        transform: scale(1.1); }
    100% {
        transform: scale(1); } }

@keyframes swimming-rtl {
    0% {
        transform: scale(1) rotateY(180deg); }
    50% {
        transform: scale(1.1) rotateY(180deg); }
    100% {
        transform: scale(1) rotateY(180deg); } }
