.right-panel .debug-tool {
    .select-wrapper {
        min-width: 240px;
        .react-select {
            &__control {
 } }                // border-radius: 20px
        .flat-virtualized-item {
            white-space: nowrap; } }
    .select-title {
        margin-right: 12px; }
    .map-title {
        font-size: 13px;
        margin-bottom: 8px; }
    .map-block {
        position: relative;
        overflow: hidden;
        // height: 600px
        border: 2px solid #B3B3B3;
        background: #E6E6E6;
        // margin-top: 10px
        .no-floor-image {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center; }
        .drawing {
            cursor: url(/images/mapping/cursor.png), auto;
            svg {
                circle:hover {
                    cursor: url(/images/mapping/cursor.png), auto; } } }
        svg {
            position: absolute;
            circle:hover {
                cursor: pointer;
                background: yellow; } }
        &.imagesList {
            .imageContainer {
                padding-top: 10px;
                position: relative;
                height: fit-content;
                .noImage {
                    height: 100px;
                    background-color: #e9ecef;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    &.active {
                        border: 3px solid #ff9400; } }
                .imageTag {
                    background-color: #e9ecef;
                    text-align: center;
                    min-height: 100px;
                    &.active {
                        border: 3px solid #ff9400; } }
                .checkbox {
                    position: absolute;
                    right: 15px;
                    top: 15px;
                    width: 25px;
                    height: 25px;
                    cursor: pointer;
                    display: none; }
                img {
                    width: auto;
                    height: auto;
                    // max-height: 300px
 }                    // cursor: pointer
                &.hover {
                    .checkbox {
                        display: block; } }
                &.checked {
                    .checkbox {
                        display: block; } } } } }
    .rdtPicker {
        .rdtDay {
            &:not(.rdtDisabled) {
                font-weight: 600;
                font-style: italic; }
            &.rdtDisabled {
                opacity: 0.8; } }
        .rdtDays {
            th {
                font-weight: 600; } } } }
