.event {
  z-index: 10;
  top: 20vh;
  right: 0;
  position: fixed;
  width: 47%;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  background-color: #FFFFFF;
  padding: 40px;
  .line {
    width: 100%;
    height: 2px;
    background-color: #5890E1; }
  .line.black {
    height: 1px;
    background-color: #707070;
    margin-bottom: 23px; }
  .head {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
    .title {
      font-size: 16px;
      color: #5890E1;
      text-transform: uppercase;
      font-weight: 600; }
    .btn_wrap {
      display: flex;
      align-items: center;
      .edit {
        position: relative;
        border: none;
        background: none;
        width: 30px;
        height: 30px;
        margin-right: 20px;
        outline: none;
        &:before {
          content: url("/images/edit.svg");
          top: 0; } } } }
  .event_name {
    display: flex;
    align-items: center;
    color: #545454;
    margin-bottom: 35px;
    .name {
      font-size: 30px;
      margin: 0 50px 0 0;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        background: #FFC654;
        width: 4px;
        height: 100%;
        margin-left: 23px; } }
    .location {
      font-size: 16px;
      margin: 0; }
    div.date_wrap {
      margin-left: 5%;
      .title {
        font-weight: 600;
        margin: 0; }
      .description {
        opacity: 0.8; } } }
  .information {
    display: flex;
    position: relative;
    flex-direction: column;
    .title {
      font-size: 16px;
      position: relative;
      margin: 0 0 0 18px;
      &:before {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        background: #FFC654;
        top: 4px;
        left: -18px; } }
    .top_content {
      display: flex;
      margin-bottom: 30px;
      .chartjs-render-monitor {
        height: 100px !important;
        width: auto !important;
        position: absolute;
        right: 18px;
        top: 55px; }
      .logo {
        width: 50%;
        img {
          width: 100%;
          height: auto;
          border-radius: 30px; } }
      .data {
        width: 50%;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        .info {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          &:last-child {
            margin-top: 20px; }
          #line {
            position: absolute;
            display: block;
            height: 155px;
            top: 0;
            right: 0;
            left: 0;
            margin: 0 auto; }
          .data_content {
            width: 48%;
            position: relative;
            .radar_wrap {
              width: 100%;
              height: 100%;
              position: relative;
              .chartjs-render-monitor {
                position: absolute;
                left: 0;
                top: 0;
                margin: auto; } }
            .price {
              font-size: 34px; }
            .text_info {
              font-size: 9px; } } } } }
    .bottom_content {
      display: flex;
      position: relative;
      justify-content: space-between;
      .comments {
        ol {
          padding-left: 10px; }
        .comment {
          font-size: 16px;
          color: #545454;
          margin-bottom: 10px; } }
      .graph {
        position: absolute;
        right: 0;
        top: -60px;
        width: 250px; }
      .percentages {
        display: flex;
        margin: 55px 0 10px;
        .percent {
          width: 70px;
          height: 80px;
          margin-right: 10px;
          border-bottom-right-radius: 30px;
          border-top-left-radius: 30px;
          text-align: center;
          font-size: 32px;
          padding: 7px;
          color: #fff;
          p {
            text-align: justify;
            font-size: 4px; }
          &:last-child {
            margin-right: 0; } }
        .percent-brown {
          background-color: #515355; }
        .percent-blue {
          background-color: #006DB7; }
        .percent-dark-blue {
          background-color: #00487A; } } } }

  .footer {
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    color: #fff;
    margin-top: 5px;
    .stat-wrapper {
      display: flex;
      align-items: flex-end;
      img {
        height: 60px;
        vertical-align: bottom; }
      .percentage {
        padding: 6px 25px;
        font-size: 25px;
        margin-left: 5px; } } }

  .event {
    top: calc(90px + 2%);
    position: fixed; }
  .event.disabled {
    display: none; } }
