.camera-heatmap {
    background-color: #ffffff;

    .btn {
        font-family: Poppins, inherit; //Poppins hasn't hebrew support
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        box-sizing: border-box;
        border-radius: 4px;
        border-width: 1px;
        border-style: solid;
        // &.add-polygon
        //     padding: 10px 17px 10px 34px
        //     margin-right: 32px
        //     color: #201158
        //     background-image: url(/images/mapping/pen.png) !important
        //     background-repeat: no-repeat !important
        //     background-position: 16px center !important
        //     &:focus
        //         border: 1px solid #007bff !important
        &.add-polygon {
            padding: 10px 17px 10px 36px;
            margin-right: 32px;
            color: #007BFF;
            background-image: url(/images/zone/pen.png) !important;
            background-repeat: no-repeat !important;
            background-position: 16px center !important;
            &:hover, &:focus {
                background-image: url(/images/zone/penHover.png) !important;
                color: #fff;
                background-color: #007bff !important;
                border: 1px solid #007bff !important; }
            &.btn-disabled {
                background-image: url(/images/zone/penDisabled.png) !important; } }
        &.apply-map {
            padding: 10px 36px;
            &:focus {
                border: 1px solid #19AB27 !important; } }
        &.clear-polygon {
            padding: 10px 17px 10px 36px;
            margin-right: 32px;
            color: #E14E5C;
            background-image: url(/images/zone/delete.png) !important;
            background-repeat: no-repeat !important;
            background-position: 16px center !important;
            border: 1px solid #E14E5C !important;
            &:hover, &:focus {
                background-image: url(/images/zone/deleteHover.png) !important;
                background-color: #dc3545 !important;
                color: #fff; }
            &.btn-disabled {
                background-image: url(/images/zone/deleteDisabled.png) !important; } }
        &.btn-disabled {
            background-color: #E6E6E6 !important;
            border-color: #b3b3b3 !important;
            cursor: auto !important;
            &:hover, &:focus {
                background-color: #E6E6E6 !important; } } }
    .stepper {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        .stepper-number {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .stepper-index {
                color: #fff;
                width: 24px;
                height: 24px;
                background: #007BFF;
                border-radius: 50%;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 20px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-right: 5px;
                &.accepted {
                    background: #FFF url(/images/mapping/check.png) no-repeat center center !important;
                    border: 1px solid #007BFF !important; } }
            .link {
                cursor: pointer;
                &:hover {
                    text-decoration: underline; } }
            &.active {
                color: #007BFF; }
            &.disabled {
                color: #B3B3B3;
                .stepper-index {
                    color: #6C757D;
                    border: 1px solid #6C757D;
                    background-color: #fff; } } }
        .stepper-connector {
            min-width: 60px;
            margin: 4px;
            border: 1px solid #6C757D; } }

    .react-transform-element {
        // width: auto
        height: auto;
 }        // display: initial
    .react-transform-component {
        width: 100%;
        height: 100%;
        // img
        //     max-width:  100%;
        //     max-height: 100%;
 }        //     display: block;
    .map-title {
        font-size: 13px;
        margin-bottom: 8px; }
    .map-block {
        position: relative;
        overflow: hidden;
        height: 600px;
        border: 2px solid #B3B3B3;
        background: #E6E6E6;
        .no-floor-image {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center; }
        .drawing {
            cursor: url(/images/mapping/cursor.png), auto;
            svg {
                circle:hover {
                    cursor: url(/images/mapping/cursor.png), auto; } } }
        svg {
            position: absolute;
            circle:hover {
                cursor: pointer;
                background: yellow; } } }

    .markers-block {
        background: #E9ECEF;
        border: 1px dashed #6C757D;
        border-radius: 4px;
        box-sizing: border-box;
        margin: 24px auto 0;
        .marker {
            margin: 10px 8px;
            cursor: pointer; } }

    .floor-dropdown {
        .dropdown-toggle {
            background-color: #fff !important; }
        position: absolute;
        right: 10px;
        top: 24px;
        button {
            width: 130px;
            height: 40px;
            border: 1px solid #CED4DA !important;
            box-sizing: border-box;
            border-radius: 4px;
            color: #201158 !important;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            text-align: left;
            background: #FFF url(/images/vector.png) no-repeat 104px center;
            &::after {
                border-left: 1px solid #CED4DA;
                float: right;
                height: 20px;
                margin-right: 21px;
                border-top: none;
                border-right: none;
                border-bottom: none; } }
        .dropdown-menu {
            min-width: 131px;
            .dropdown-item {
                text-align: center; } } } }

.tooltip {
    &.markers-block-disabled {
        .arrow {
            display: none; }
        .tooltip-inner {
            background: rgba(0, 0, 0, 0.5);
            max-width: 287px;
            border-radius: 2px;
            padding: 4px 8px;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            color: #fff; } }
    &.remove-marker-tooltip {
        .arrow {
            display: none; }
        .tooltip-inner {
            background: rgba(0, 0, 0, 0.5);
            max-width: 287px;
            border-radius: 2px;
            padding: 4px 8px;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            color: #fff;
            .remove-marker {
                margin-left: 18px;
                font-weight: bold;
                font-size: 12px;
                line-height: 18px; }
            &:hover {
                cursor: pointer; } } }
    &.remove-icon {
        .tooltip-inner {
            background: rgba(0, 0, 0, 0.5) url(/images/mapping/cross.png) no-repeat 10px center; } }
    &.edit-icon {
        .tooltip-inner {
            background: rgba(0, 0, 0, 0.5) url(/images/mapping/add_point.png) no-repeat 2px center; } }
    &.remove-point-icon {
        .tooltip-inner {
            background: rgba(0, 0, 0, 0.5) url(/images/mapping/remove_point.png) no-repeat 2px center; } } }

.ddcontainerghost {
    display: none !important; }
