.topshopsWrapper {
    min-width: 400px;
    margin-left: 33px;
    z-index: 10;
    &.full {
        left: 0;
        position: absolute;
        right: 0;
        .topshops .arrow {
            background-image: url(/images/cross.png); } }
    .topshops {
        // width: 400px
        // margin-left: 33px
        // position: relative
        // width: 100%
        position: absolute !important;
        .arrow {
            width: 48px;
            height: 48px;
            position: absolute;
            left: -24px;
            top: calc(50% - 24px);
            border-radius: 100px;
            cursor: pointer;
            background: #fff url(/images/arrow-left.png) no-repeat 8px center;
            box-shadow: -1px 1px 9px rgba(0, 0, 0, 0.11); }
        .card-header {
            // display: flex
            // justify-content: end
            // align-items: center
            overflow: hidden;
            margin-left: 0 !important;
            .toggleWrapper {
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                margin-left: 16px;
                span {
                    font-size: 13px;
                    line-height: 13px;
                    margin-right: 8px; } }
            .title {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                white-space: nowrap; }
            .zoneRow {
                border: none;
                .column {
                    margin: 0 0 8px 0;
                    // padding-right: 0
                    &.active {
                        // min-width: 110px
                        color: #007BFF; } } } }
        .card-body {
            z-index: 1;
            padding-left: 0 !important;
            padding-right: 0 !important; }
        .zoneRow {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #ccc;
            padding-left: 16px;
            .column {
                width: 150px;
                margin: 16px 0;
                text-align: right;
                padding-right: 40px;
                // &.active
 }                //     min-width: 110px
            .zoneName {
                // min-width: 230px
                min-width: 185px;
                display: flex;
                align-items: center; } }
        .zoneCheckbox {
            height: 16px;
            width: 16px;
            margin-right: 8px; }
        .title {
            font-size: 16px;
            line-height: 24px; }
        .nav-tabs {
            display: table;
            margin-top: 0;
            margin-bottom: 10px;
            .nav-link {
                display: table-cell;
                width: 1%;
                color: #397E6F;
                font-weight: 300;
                line-height: 35px;
                font-size: 24px;
                border-right: 1px solid #48C3A8;
                padding-top: 0;
                padding-bottom: 0;
                text-align: center;
                &.active {
                    border: none;
                    font-weight: 600;
                    border-right: 1px solid #48C3A8; }
                &:first-child {
                    padding-left: 12px; }
                &:last-child {
                    border: none; } } }
        .list-group {
            height: 546px;
            overflow-y: auto;
            overflow-x: hidden;
            font-size: 14px;
            background: #fff;
            // margin-top: 23px
            &.displaySelectedZones {
                max-height: 546px;
                height: auto; }
            .list-group-item {
                background-color: #FBFBFB;
                border: 1px solid #fff;
                margin-bottom: 10px;
                color: #545454;
                font-size: 17px;
                line-height: 21px;
                padding-left: 5px;
                padding-right: 5px;
                div {
                    padding: 0 5px; }
                img {
                    width: 26px;
                    height: 30px;
                    border-radius: 16px;
                    margin-right: 5px;
                    &.shop-img {
                        border-radius: 0;
                        width: 36px;
                        max-height: 42px;
                        height: auto;
                        float: left;
                        margin-top: 5px;
                        display: inline-block; } }
                .header {
                    font-size: 15px;
                    line-height: 14px;
                    margin-bottom: 8px; }
                .shop-name {
                    cursor: pointer;
                    color: #201158;
                    font-size: 16px;
                    line-height: 24px;
                    &:hover {
                        text-decoration: underline; } }
                .indicator-block {
                    font-size: 16px;
                    line-height: 24px;
                    color: #495057;
                    display: flex;
                    justify-content: center;
                    &_title {
                        color: #6C757D; }
                    &.ltr {
                        .indicator-block_title {
                            margin-left: 5px; } }
                    &.rtl {
                        .indicator-block_value {
                            margin-left: 5px; } } }
                .avg-visit-time {
                    font-size: 16px; }
                &:last-child {
                    margin-bottom: 4px; } }
            &.floor-tab {
                margin-top: 8px;
                .list-group-item {
                    line-height: 19px;
                    &:last-child {
                        margin-bottom: 0; } } } }
        .dropdown_wrap {
            margin-top: 10px;
            .show > .btn-outline-dark.dropdown-toggle {
                background-color: #fff !important;
                color: #343a40 !important;
                border: 1px solid #343a40 !important; }
            .btn {
                line-height: 12px;
                font-size: 14px;
                &:hover, &:active, &:focus, &:visited {
                    background-color: #fff !important;
                    color: #343a40 !important;
                    border: 1px solid #343a40 !important; } } } } }
#popover-shop {
    box-shadow: 2px 3px 6px 0 rgba(45, 45, 45, 0.62);
    // border-radius: 24px
    display: block;
    position: fixed;
    z-index: 1000;
    width: 280px;
    height: 250px;
    max-width: initial;
    font-size: 12px;
    .shop-location {
        font-weight: 300;
        margin: 5px 0;
        display: flex;
        justify-content: space-between;
        .type {
            margin-left: 15px;
            font-size: 15px;
            line-height: 20px; }
        .floor {
            font-size: 14px;
            margin-right: 19px; } }
    .shop-image {
        img {
            height: auto;
            width: 100%; } }
    .content-wrapper {
        display: flex;
        justify-content: space-between;
        .shop-name {
            font-size: 22px;
            line-height: 29px;
            margin-left: 12px; }
        .shop-rent {
            margin-right: 20px;
            color: #545454;
            font-size: 14px;
            .title {
                span {
                    background-color: #fbc02d;
                    width: 10px;
                    height: 10px;
                    display: inline-block; } }
            .rent {
                font-size: 18px;
                line-height: 16px;
                letter-spacing: 0.8px; } } }
    .shop-owner {
        font-size: 20px;
        line-height: 26px;
        margin-top: 5px;
        margin-left: 12px;
        img {
            width: 26px;
            height: 30px;
            border-radius: 16px;
            margin-right: 5px; } } }
