header {
  position: fixed;
  z-index: 15;
  top: 0;
  right: 0;
  width: 92%;
  background-color: #fff;
  padding: 0 80px;
  height: 90px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  transition: width 0.3s ease 0.05s;
  div.row {
    width: 100%;
    height: 100%;
    div.col-md-6 {
      height: 100%;
      .header-left {
        height: 100%;
        .navbar {
          height: 100%;
          .navbar-brand {
            height: 100%;
            .logo {
              height: 100%;
              font-size: 27px;
              display: inline-block;
              font-weight: 900;
              img {
                height: 100%; }
              &:hover {
                cursor: pointer; } }
            .bread_crumb {
              font-size: 15px;
              display: inline-block;
              font-weight: 300;
              &:first-letter {
                text-transform: uppercase; } } } } } } }

  .header-right {
    height: 100%;
    font-weight: 300;
    font-size: 15px;
    .navbar {
      height: 100%;
      &>.nav-item {
        margin-right: 25px;
        &:last-child {
          margin-right: 0; } }
      .dropdown-toggle {
        padding: 0;
        background: none;
        border: none;
        color: #000;
        span[role=button] {
          padding: 15px;
          display: inline-block;
          line-height: 20px; }
        &:hover {
          background: none !important;
          border: none; } }
      .setting-dropdown {
        .dropdown-nav_item {
          display: block;
          width: 100%;
          padding: 0.25rem 1.5rem;
          clear: both;
          font-weight: 400;
          color: #212529;
          text-align: inherit;
          white-space: nowrap;
          background-color: transparent;
          border: 0;
          &:hover {
            text-decoration: none;
            text-transform: none;
            background: rgba(32, 17, 88, 0.05); }
          &:focus {
            outline: none; }
          &.language-dropdown {
            .dropdown-toggle {
              width: 100%;
              text-align: left;
              &:active {
                color: initial; } }
            .dropdown-menu.show {
              min-width: 130px;
              box-shadow: 0 1px 3px 0 rgba(32, 17, 88, 0.5);
              .dropdown-item {
                &.selected {
                  background: #201158;
                  color: #fff;
                  &:hover {
                    background: #201158;
                    color: #fff; } }
                &:hover {
                  text-decoration: none;
                  text-transform: none;
                  background: rgba(32, 17, 88, 0.05); }
                &:active {
                  background: initial;
                  color: initial; }
                &:focus {
                  outline: none; } } } } } } }


    .user-avatar {
      width: 40px;
      border-radius: 50%;
      margin-right: 10px; }
    .user-name {
      margin: 0;
      display: inherit; }
    .search-input {
      background-color: #f5f5f5;
      border: none;
      width: 80%;
      color: #201158;
      border-radius: 0 21px 21px 0;
      font-weight: 300; }
    .search-btn {
      background-color: #f5f5f5;
      color: #201158;
      padding: 0 1px 0 20px;
      border-radius: 21px 0 0 21px;
      border: none;
      font-size: 14px;
      &:hover {
        background: #f5f5f5 !important; } }
    .notif_wrap {
      #user_notifications {
        position: relative;
        .bell_badge {
          width: 18px;
          height: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          top: -4px;
          right: -5px;
          background-color: #db5565;
          position: absolute;
          min-width: 10px;
          font-size: 12px;
          font-weight: bold;
          color: #ffffff;
          line-height: 1;
          vertical-align: baseline;
          white-space: nowrap;
          text-align: center; }
        &:after {
          display: none; } }
      .dropdown-menu {
        background-color: transparent;
        width: 15rem;
        border: none;
        padding: 0;
        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          backdrop-filter: blur(8px); }
        .notif {
          width: 100%;
          overflow: hidden;
          border-radius: 4px;
          position: relative;
          z-index: 2;
          color: #ffffff;
          opacity: .94;
          box-shadow: 2px 2px 10px rgba(0,0,0,0.4);
          float: left;
          font-family: Arial, Helvetica, sans-serif, sans-serif;
          font-size: 1em;
          text-align: left;
          padding: 10px;
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0; }
          h6 {
            font-size: 1.1em;
            font-weight: bold; }

          span {
            font-size: 14px; }
          .close_btn {
            &:before, &:after {
              background-color: #ffffff; } } }
        .success {
          background-color: #60bb71; }
        .warning {
          background-color: #f7a336; }
        .error {
          background-color: #db6a64; }
        .info {
          background-color: #58abc3; } } }
    .notif_wrap.scroll {
      .dropdown-menu {
        height: 40vh;
        overflow-y: scroll; } } } }

header.hide {
  width: 96%;
  transition: width 0.3s ease 0.05s; }
