.start_page_wrapper {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-image: url("/images/login_back.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  .main_block {
    width: 23%;
    flex-direction: column;
    display: flex;
    .welcome_wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #ffffff;
      h4 {
        font-size: 24px;
        font-weight: 700;
        margin: 0; }
      h5 {
        font-size: 36px;
        font-weight: 800;
        margin-bottom: 3px; } }
    .log_in_block {
      position: relative;
      background-color: #fff;
      border: 1px solid #201158;
      padding: 30px 30px 10px;
      width: 100%;
      display: flex;
      flex-direction: column;
      border-radius: 20px;
      .close_btn {
        outline: none;
        left: 17px;
        top: 9px; }
      h3 {
        color: #201158; }
      .description {
        margin-top: 5px;
        font-size: 16px;
        color: #858097; }
      .form-group {
        input.form-control {
          border-radius: 20px;
          border: 1px solid #201158;
          &::placeholder {
            color: #201158;
            font-size: 14px; } } }
      .option_wrap {
        width: 85%;
        display: flex;
        justify-content: space-between;
        margin: 2px auto 3px;
        .form-group {
          .custom-control-label {
            font-size: 14px; }
          .custom-control-input {
            font-size: 14px;
            &:checked ~ .custom-control-label {
              &:before {
                border-color: #201158;
                background-color: #201158;
                top: 0.05rem; }
              &:after {
                top: 0.05rem; } } }
          .custom-control-label {
            &:before {
              top: 0.05rem;
              border-radius: 0.15rem; } } }
        .forgot_pass-button {
          color: #201158;
          text-decoration: underline;
          margin: -10px 0 7px;
          border: none;
          background: none;
          text-align: right;
          font-size: 14px; } }
      .submit {
        width: 100%;
        border-radius: 20px;
        font-size: 18px;
        color: #201158;
        padding: 4px 0;
        &:hover {
          color: #000 !important; } }
      .submit.login {
        background-color: #201158;
        margin-bottom: 10px;
        color: #fff; }
      .copyright {
        color: #000;
        margin: 10px 0 5px;
        font-size: 13px; } } } }
