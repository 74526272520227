.right-panel {
  .content {
    .comparison.events-comparison {
      top: 90px;
      height: calc(100vh - 90px);
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      bottom: auto;
      flex-wrap: wrap;
      padding: 20px 0;
      overflow-x: auto;
      transition: left 0.3s ease 0.05s;
      .events_column {
        min-width: 48%;
        width: 48%;
        .event {
          width: 100%;
          position: relative;
          top: auto;
          border-radius: 50px;
          margin-top: 20px;
          &:nth-child(1) {
            margin-top: 0; } } } }
    .comparison.events-comparison.editing {
      z-index: 4; } }
  .content.event.disabled {
    .event.editing {
        z-index: 4; } } }

.right-panel.hide {
  .content {
    .comparison.events-comparison {
      transition: left 0.3s ease 0.05s;
      left: 4%; } } }

#style-4::-webkit-scrollbar {
  width: 0; }
#style-4 {
  -ms-overflow-style: none; }
#style-4 {
  overflow: -moz-scrollbars-none;
  scrollbar-width: none; }
