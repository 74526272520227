.store_list-wrap {
  position: fixed;
  width: 18%;
  background: #E4E4E4;
  top: 90px;
  right: 0;
  z-index: 11;
  height: calc(100% - 90px);
  .header {
    width: 100%;
    border-bottom: 3px solid #ffffff;
    .close_btn {
      right: 8px;
      &:after,&:before {
        background-color: #201158; } }
    .wrap_head {
      display: flex;
      justify-content: space-around;
      .wrap_for_amount_of_list {
        display: flex;
        align-items: center;
        color: #201158;
        h3 {
          font-size: 44px;
          margin: 10px 0; }
        .selected {
          display: flex;
          flex-direction: column;
          margin-left: 0.5rem;
          p {
            font-size: 12px;
            margin: 0 0 -5px 0; }
          h6 {
            font-size: 20px;
            margin: 0; } } }
      .balance {
        display: flex;
        align-items: center;
        button {
          background: none;
          border: none;
          transition: 0.2s;
          outline: none;
          &:hover {
            transform: scale(1.2); }
          img {
            width: 100%; } } } } }
  .data {
    display: flex;
    flex-direction: column;
    .search_input {
      padding: 5px 0 5px 50px;
      font-size: 12px;
      border: none;
      border-radius: 1rem;
      margin: 20px 30px 20px;
      outline: none;
      color: #201158;
      background:  4% / 15% 60% url("/images/search.svg") #ffffff no-repeat; }
    .events_wrap {
      .event_card {
        position: relative;
        margin: 0 15px 15px;
        background: #fff;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        .close_btn {
          right: 9px;
          top: 6px; }
        .head {
          display: flex;
          justify-content: space-between;
          margin: 5px 22px;
          .location, .date {
            color: #201158;
            margin: 0; }
          .location {
            font-size: 15px; }
          .date {
            font-size: 14px; } }
        .img {
          width: 100%;
          max-height: 250px;
          height: auto;
          overflow: hidden;
          img {
            width: 100%;
            height: auto; } }
        .wrap_for_content {
          display: flex;
          justify-content: space-between;
          margin: 0 10px;
          .wrap {
            display: flex;
            flex-direction: column;
            .name {
              margin: 10px 0 2px;
              font-size: 22px; }
            .user {
              display: flex;
              align-items: center;
              .avatar {
                width: 15%;
                margin-right: 4px;
                img {
                  width: 100%; } }
              .user_name {
                font-size: 14px;
                margin: 0; } }
            .rent {
              font-size: 14px;
              color: #545454;
              position: relative;
              margin: 5px 30px 0;
              &:before {
                content: "";
                position: absolute;
                width: 12px;
                height: 12px;
                background: #FFC654;
                top: 4px;
                left: -18px; } }
            .sum {
              font-size: 18px;
              color: #545454;
              margin: 0 0 0 22px; } } }
        .time {
          font-size: 14px;
          margin: 10px 10px 0; }
        .gender {
          display: flex;
          margin: 5px 15px 15px;
          justify-content: space-between;
          .stat-wrapper {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            img {
              vertical-align: bottom;
              margin: 0 auto 2px; }
            .percentage {
              padding: 4px 6px;
              color: #fff;
              text-align: center; } } } } }
    .scrollbar {
      height: calc(100vh - 240px);
      margin-right: 5px;
      overflow-y: scroll; } } }



#style-2::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: transparent; }
#style-2::-webkit-scrollbar {
  width: 12px;
  background-color: transparent; }
#style-2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: 0 2px 2px #000000;
  background-color: rgba(26, 12, 99, 0.82); }
