.mall-map {
    .panel-header {
        padding: 10px 8px;
        border-radius: 100px;
        background: rgba(0, 0, 0, 0.1);
        margin: 4px;
        display: flex;
        justify-content: space-between;
        .btnCompareZones {
            border-radius: 100px;
            background-color: #fff;
            &:disabled {
                background-color: transparent;
                color: #B2B2B2;
                border-color: #B2B2B2; } } }
    .mallmap {
        overflow: auto;
        position: relative;
        .heatmap-canvas {
            opacity: 0.5;
            width: 100% !important;
            height: auto !important;
            z-index: 10; }
        svg {
            position: absolute;
            // width: 100% !important
            height: auto !important;
            z-index: 1;
            .polyText {
                font-size: 12px;
                font-weight: 100;
                cursor: pointer; }
            .zone {
                fill: transparent;
                stroke: #475ED1;
                stroke-opacity: 1;
                stroke-width: 1.5px;
                transition: 0.3s fill;
                &:hover,
                &.clicked {
                    cursor: pointer;
                    fill: #475ED1;
                    fill-opacity: 0.3; }
                &.zoneBest {
                    stroke: #19AB27;
                    &:hover,
                    &.clicked {
                        fill: rgb(31, 200, 13);
                        fill-opacity: 0.15; } }
                &.zoneWorst {
                    stroke: #E14E5C;
                    &:hover,
                    &.clicked {
                        fill: rgb(225, 78, 92);
                        fill-opacity: 0.15; } } } }
        img#image-mall {
            height: auto; }
        .shop-popover {
            z-index: 5;
            position: fixed; } }
    .heatmap_wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            color: #201158;
            font-weight: 600; } }
    #popover-basic {
        box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.11);
        border-radius: 24px;
        display: block;
        position: fixed;
        z-index: 1000;
        width: 378px;
        height: 358px;
        max-width: initial;
        font-size: 12px;
        overflow: hidden;
        .popover-content {
            .img-wrapper {
                img {
                    width: 100%;
                    height: 110px; } }
            .content-wrapper {
                padding: 30px 35px 50px 35px;
                .shop-location {
                    margin-bottom: 30px; }
                .btn-wrapper {
                    text-align: center;
                    margin-top: 35px;
                    button {
                        border: 1px solid #201158;
                        border-radius: 20px;
                        padding: 3px 15px;
                        font-size: 16px; } }
                .carousel {
                    .prev-bars {
                        position: absolute;
                        top: 20px;
                        padding-left: 0; }
                    .next-bars {
                        position: absolute;
                        top: 20px;
                        padding-right: 0;
                        right: 0; }
                    .bars {
                        width: 270px;
                        overflow: hidden;
                        margin: 0 auto;
                        ul {
                            height: 50px;
                            width: 9999px;
                            margin: 0;
                            padding: 0;
                            font-size: 10px;
                            li {
                                width: 12px;
                                background-color: #E4E2EB;
                                margin-right: 3px;
                                display: inline-block;
                                position: relative;
                                border-radius: 3px 3px 0 0;
                                .title {
                                    position: absolute;
                                    bottom: 0;
                                    z-index: 1; } } } } } } } } }
.mall-map.disabled {
    position: relative;
    &:before {
        position: absolute;
        content: "";
        z-index: 5;
        opacity: 0.7;
        background-color: #fff;
        width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0; } }

.tooltip.zone_tooltip {
    z-index: 7;
    &.show {
        opacity: 1; }
    .arrow {
        &:before {
            border-top-color: #475ED1;
            top: 0; } }
    .tooltip-inner {
        background-color: #475ED1;
        color: #fff;
        width: 110px;
        padding-left: 4px;
        padding-right: 4px;
        .filterRow {
            .value {
                float: right; } } }
    &.zoneBest {
        .tooltip-inner {
            background-color: #19AB27; }
        .arrow {
            &:before {
                border-top-color: #19AB27; } } }
    &.zoneWorst {
        .tooltip-inner {
            background-color: #E14E5C; }
        .arrow {
            &:before {
                border-top-color: #E14E5C; } } } }

