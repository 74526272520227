.DashboardCalendarPopover {
    padding-bottom: 50px;
    .popover-header {
        background-color: #fff;
        border: none; }
    .popover-body {
        .rdrDateDisplayWrapper {
            background-color: #fff;
            .rdrDateDisplayItem {
                border: 1px solid #ccc;
                &.rdrDateDisplayItemActive {
                    color: #007BFF;
                    border: 1px solid #007BFF; } } }
        .rdrMonthAndYearWrapper {
            height: 30px;
            padding: 0; }
        .title {
            position: absolute;
            top: 57px;
            color: #495057; }
        .button {
            font-size: 13px;
            line-height: 20px;
            &.applyButton {
                position: absolute;
                right: 24px;
                bottom: 24px;
                &:disabled {
                    background: #E6E6E6;
                    border: 1px solid #B3B3B3;
                    color: #B3B3B3; } } } }
    .DashboardCalendar {
        // width: 230px
        .rdrDefinedRangesWrapper {
            position: absolute;
            top: 90px;
            width: 120px;
            border: none;
            margin-right: 12px;
            .rdrStaticRanges {
                .rdrStaticRange {
                    .rdrStaticRangeLabel {
                        padding: 8px 15px 10px 8px;
                        font-size: 13px;
                        line-height: 20px;
                        font-weight: normal;
                        .predefinedOptions {
                            &.disabled {
                                cursor: auto;
                                background: #E6E6E6;
                                color: #B2B2B2; } } }
                    &.rdrStaticRangeSelected {
                        background-color: #007BFF;
                        color: #fff !important;
 }                        // rgba(0, 0, 0, 0.1)
                    &:hover.rdrStaticRangeSelected {
                        .rdrStaticRangeLabel {
                            background-color: #007BFF; } } } } }
        .rdrDateRangeWrapper {
            padding-left: 130px; } } }

.DashboardCalendarComparePopover {
    .DashboardCalendar {
        .rdrDefinedRangesWrapper {
            .rdrStaticRanges {
                .rdrStaticRange {
                    .rdrStaticRangeLabel {
                        padding: 0;
                        .predefinedOptions {
                            padding: 8px 15px 10px 8px; } } } } } } }
