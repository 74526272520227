.create_user {
  width: 85%;
  display: flex;
  border-radius: 0.5rem;
  padding: 0 10px;
  position: relative;
  background: #fff;
  z-index: 9;
  margin: 0 auto;
  max-height: calc(100vh - 90px - 40px);
  //overflow: auto
  .sign_up_block {
    width: 100%;
    border-radius: 20px;
    position: relative;
    padding: 20px 15px;
    .user-type-description {
      text-align: center;
      margin: 10px 0 20px; }
    .inputs-wrap {
      display: flex;
      justify-content: space-between;
      &.four {
        .form-group {
          width: 24%; } }
      &.three {
        .form-group {
          width: 32%; } } }
    .wrap_for_dropdown-buttons {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      .form-group {
        width: 49%;
        .dropdown {
          button {
            width: 100%;
            &:hover, &:active {
              color: #000 !important;
              border: 1px solid #343a40 !important; } }
          //.dropdown-menu.show
 }          //  width: 50%
        .react-datepicker-wrapper {
          .react-datepicker__input-container {
            width: 100%;
            input {
              text-align: center;
              width: 100%;
              border-radius: 0.25rem;
              border: 1px solid #000;
              padding: 6px 20px; } } } } }
    h3 {
      color: #201158; }
    .description {
      font-size: 16px;
 }      //color: #858097
    .form-group {
      input.form-control {
        border-radius: 20px;
        border: 1px solid #201158;
        &::placeholder {
          color: #201158;
          font-size: 14px; } } }
    .submit {
      width: 100%;
      border-radius: 20px;
      font-size: 18px;
      color: #201158;
      padding: 4px 0;
      &:hover {
        background: inherit; } }
    .submit.login {
      background-color: #201158;
      color: #fff; }
    .select-type_wrap {
      margin-bottom: 15px;
      display: flex;
      justify-content: space-around;
      .type {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 80px;
        opacity: 0.4;
        cursor: pointer;
        &.disabled {
          cursor: auto; }
        img {
          width: 50%;
          margin: 0 auto; }
        .name {
          color: #201158;
          font-size: 14px;
          margin: 0; } }
      .type.active {
        opacity: 1; } }
    .data_wrap {
      display: flex;
      justify-content: space-between; } }
  .description_user-type {
    display: flex;
    margin: 20px 20px 20px 0;
    padding: 20px;
    width: 65%;
    background-color: #201158;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    .picture {
      width: 20%;
      height: 260px;
      background-color: #fff;
      margin-top: 11%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 50%; } }
    .info_wrap {
      width: 80%;
      padding-left: 20px;
      .head_wrap {
        display: flex;
        justify-content: space-between;
        h5 {
          color: #ffffff;
          font-size: 28px;
          margin: 15px 0 0; }
        h4 {
          font-size: 32px;
          color: #ffffff;
          font-weight: 800; } }
      .describe {
        color: #A49DC0;
        font-size: 16px;
        margin-top: 50px; }
      .description {
        margin-top: 30px;
        color: #ffffff;
        font-size: 14px; } } } }

@media (max-width: 1440px) {
  h3 {
    font-size: 1.5rem !important; }
  .form-label, .react-datepicker-wrapper, .form-control, #dropdown--button-for_malls {
    font-size: 0.8rem !important; }
  .select-type_wrap {
    .type {
      height: 70px !important; } } }
