.add_new_data {
  width: 80%;
  display: block;
  border-radius: 0.5rem;
  padding: 40px;
  position: absolute;
  background: #fff;
  z-index: 10;
  margin: 0 auto;
  &.mall_cameras_editing {
    position: initial; }
  h3.edit_title {
    font-size: 37px;
    color: #21ADB8; }
  .cameras, .double_wrap {
    display: flex;
    justify-content: space-between;
    .form-group {
      width: 49%; } }
  .cameras_option {
    .form-group {
      .dropdown-toggle {
        width: 100%; }
      .react-select__multi-value {
        background-color: rgba(66,133,244,0.12);
        border: 1px solid #d2e3fc; } }
    .attr_checkbox {
      width: 18%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      .form-label {
        margin-bottom: 0; } } }
  .main_data {
    display: flex;
    justify-content: space-between;
    .main_info {
      width: 69%;
      .prof_wrap {
        display: flex;
        justify-content: space-between;
        .form-group {
          width: 49%; } }
      .prof_wrap.three {
        .form-group {
          width: 32%;
          .dropdown {
            button {
              width: 100%; } } } }
      .prof_wrap.four {
        .form-group {
          width: 24%;
          .dropdown {
            button {
              width: 100%; } } }
        .react-datepicker-wrapper {
          width: 100%;
          display: block;
          .react-datepicker__input-container {
            width: 100%;
            input {
              text-align: center;
              width: 100%;
              border-radius: 0.25rem;
              border: 1px solid #ced4da;
              color: #495057;
              padding: 6px 20px; } } } } }
    .main_info.user {
      width: 79%; }
    .main_info.owners {
      width: 75%; }
    .avatar_block {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 29%;
      position: relative;
      &:hover {
        .close_btn, .form-group {
          opacity: 1;
          transition: 0.5s; } }
      .photo {
        max-width: 100%;
        height: 250px; }
      .photo.user {
        height: 165px; }
      .close_btn {
        transition: 0.3s; }
      .form-group {
        opacity: 0;
        transition: 0.3s;
        position: absolute;
        bottom: calc(50% - 30px);
        margin: 0;
        cursor: pointer;
        .inputfile {
          width: 0.1px;
          height: 0.1px;
          overflow: hidden;
          position: absolute;
          z-index: -1; }
        .labelForPhoto {
          cursor: pointer;
          margin: 0;
          &:hover {
            cursor: pointer; }
          img {
            width: 100%; } }
        &:hover {
          cursor: pointer; } }
      .close_btn {
        opacity: 0;
        right: 16px;
        outline: none;
        &:before, &:after {
          right: 0;
          height: 20px;
          background-color: #dc3545; } }
      .close_btn.local {
        top: 16px; } }
    .avatar_block.user {
      width: 20%; }
    .avatar_block.owners {
      width: 23%;
      .photo {
        height: 170px; } } }
  .other_info {
    display: flex;
    justify-content: space-between;
    .form-group {
      width: 49%; } }
  .other_info.prof {
    .form-group {
      width: 32%; } }
  .other_info.nvr {
    border: 1px solid rgba(32, 17, 88, 0.7);
    margin-bottom: 20px;
    padding: 15px;
    .form-group {
      width: 24%; } }
  .wrap_for_dropdown-buttons {
    display: flex;
    justify-content: space-between;
    margin: 25px 0;
    .dropdown {
      width: 33%;
      &:first-child {
        margin-right: 10px; }
      &:last-child {
        margin-left: 10px; }
      button {
        width: 100%; }
      .dropdown-menu.show {
        width: 50%; } }
    .react-datepicker-wrapper {
      width: 33%;
      .react-datepicker__input-container {
        width: 100%;
        input {
          text-align: center;
          width: 100%;
          border-radius: 0.25rem;
          border: 1px solid #000;
          padding: 6px 20px; } } } }
  .wrap_for_date {
    display: flex;
    margin: 20px 0;
    justify-content: space-between;
    .form-group {
      width: 30%;
      .dropdown, .react-datepicker-wrapper {
        .dropdown-toggle.btn.btn-outline-dark {
          width: 100%;
          height: 100%;
          input {
            width: 100%;
            height: 100%; } } }
      .react-datepicker-wrapper {
        width: 100%;
        .react-datepicker__input-container {
          width: 100%;
          input {
            text-align: center;
            width: 100%;
            border-radius: 0.25rem;
            border: 1px solid #000;
            padding: 6px 20px; } } } } }
  .submit {
    width: 100%; }
  .btn-outline-danger {
    &:hover {
      background-color: #dc3545 !important; } }
  .wrap_for_submit-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    button {
      width: 49%; } }
  .wrap-buttons {
    button {
      width: 100%; } }
  .dropdown_shop_wrap {
    width: 49%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .dropdown {
      margin: 0;
      button, label {
        width: 100%;
        margin: 0; } } }
  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1; }

  .labelForFile {
    font-size: 1rem;
    text-align: center;
    border-radius: 0.25rem;
    border: 1px solid #000;
    color: #000;
    display: inline-block;
    padding: 6px 12px;
    &:hover {
      cursor: pointer; } }
  .labelForFile.mallFile {
    width: 100%;
    margin: 0; }
  .current_avatar {
    width: 45px;
    height: auto;
    margin-left: 30px; }
  .shop_image {
    margin-left: 20px;
    max-width: 100px;
    max-height: 70px;
    width: auto;
    height: auto; }
  .form-group {
    .dropdown {
      button.dropdown-toggle {
        &:active, &:hover {
          background-color: #343a40 !important; } } } }
  .button {
    &.mr20 {
      margin-right: 20px; } } }

#snapshot_image {
  margin-bottom: 15px;
  cursor: pointer; }
