.content.prof {
  height: calc(100% - 90px);
  display: flex;
  justify-content: center;
  align-items: center;
  .profile {
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background: #f6f6f6;
    .head {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100px;
      background: #201158;
      border-radius: 20px 20px 0 0;
      color: #ffffff;
      font-size: 40px;
      box-shadow: 0 8px 5px -5px rgba(32, 17, 88, 0.65); }
    .main {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      position: relative;
      .edit {
        position: absolute;
        border: none;
        background: none;
        width: 30px;
        height: 30px;
        top: 15px;
        right: 15px;
        outline: none;
        &:before {
          content: url("/images/edit.svg");
          top: 0; } }
      .avatar {
        width: 90%;
        height: auto;
        display: flex;
        justify-content: center;
        margin-top: 30px;
        position: relative;
        img {
          max-width: 100%;
          height: 200px; } }
      table {
        width: 80%;
        border-collapse: collapse;
        border-radius: 1em;
        overflow: hidden;
        margin: 20px 0;
        box-shadow: 0 0 11px 2px rgba(201,201,201,0.3);
        tr {
          width: 100%;
          background: #ffffff;
          td {
            padding: 20px;
            border: 1px solid #f6f6f6; } } } } } }

.content.prof.disabled {
  &:before {
    //position: absolute
    position: fixed;
    content: "";
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    //width: 100%
    width: 100vw;
    left: 0;
    top: 0; } }
