.events_list-wrap {
  position: fixed;
  width: 20%;
  background: #82507F;
  top: 90px;
  right: 0;
  z-index: 4;
  height: calc(100% - 90px);
  .header {
    width: 100%;
    border-bottom: 3px solid #ffffff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .close_btn {
      outline: none;
      &:after,&:before {
        background-color: #ffffff; } }
    .wrap_for_amount_of_list {
      display: flex;
      align-items: center;
      color: #FFFFFF;
      h3 {
        font-size: 44px;
        margin: 10px 0; }
      .selected {
        display: flex;
        flex-direction: column;
        margin-left: 0.5rem;
        p {
          font-size: 12px;
          margin: 0 0 -5px 0; }
        h6 {
          font-size: 20px;
          margin: 0; } } }
    .balance {
      display: flex;
      align-items: center;
      button {
        background: none;
        outline: none;
        border: none;
        transition: 0.2s;
        &:hover {
          transform: scale(1.1); }
        img {
          width: 100%; } } } }
  .data {
    display: flex;
    flex-direction: column;
    .search_input {
      padding: 5px 0 5px 50px;
      font-size: 12px;
      border: none;
      border-radius: 1rem;
      margin: 20px 30px 10px;
      outline: none;
      color: #201158;
      background:  4% / 15% 60% url("/images/search.svg") #ffffff no-repeat; }
    .events_wrap {
      .event_card {
        position: relative;
        margin: 0 15px 15px;
        background: #fff;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        .close_btn {
          right: 6px;
          top: 5px; }
        .head {
          display: flex;
          justify-content: space-between;
          margin: 5px 25px;
          .location, .date {
            color: #201158;
            margin: 0; }
          .location {
            font-size: 15px; }
          .date {
            font-size: 14px; } }
        .img {
          width: 100%;
          max-height: 250px;
          height: auto;
          overflow: hidden;
          img {
            width: 100%;
            height: auto; } }
        .header_info {
          display: flex;
          justify-content: space-between;
          padding: 10px;
          .name {
            margin: 0;
            font-size: 22px; }
          .more {
            font-size: 14px;
            background: none;
            border: none;
            cursor: pointer;
            color: #82507F;
            outline: none;
            padding: 0;
            &:hover {
              color: #201158;
              text-decoration: underline; } } }
        .description {
          margin: 10px 15px;
          font-size: 14px; }
        .title_square {
          font-size: 14px;
          color: #545454;
          position: relative;
          margin: 5px 30px 0;
          &:before {
            content: "";
            position: absolute;
            width: 12px;
            height: 12px;
            background: #FFC654;
            top: 4px;
            left: -18px; } }
        .sum {
          font-size: 30px;
          color: #545454;
          margin: 0 0 0 20px; }
        .gender {
          display: flex;
          margin: 5px 15px 15px;
          justify-content: space-between;
          .stat-wrapper {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            img {
              vertical-align: bottom;
              margin: 0 auto 2px; }
            .percentage {
              padding: 4px 6px;
              color: #fff; } } } } }
    .scrollbar {
      height: calc(100vh - 240px);
      margin-right: 5px;
      overflow-y: scroll; } } }



#style-1::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: transparent; }
#style-1::-webkit-scrollbar {
  width: 12px;
  background-color: transparent; }
#style-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: 0 2px 2px #000000;
  background-color: #A17BA0; }
